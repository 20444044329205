import React, { useState } from 'react';
import { GridCallbackDetails, GridCellParams, GridColumnHeaderParams } from '@mui/x-data-grid-pro';

export enum TableSettingsTypes {
  TABLE_ROWS = 'TABLE_ROWS',
  TABLE_COLUMNS = 'TABLE_COLUMNS',
}

export type ToggledTableSettingsPanelRows = {
  tableApi: {
    tableCallbackDetails: GridCallbackDetails;
    selectedModel: GridCellParams;
  };
};

export type ToggledTableSettingsPanelColumns = {
  tableApi: {
    tableCallbackDetails: GridCallbackDetails;
    selectedModel: GridColumnHeaderParams;
  };
};

type ToggledTableSettingsPanel = ToggledTableSettingsPanelRows | ToggledTableSettingsPanelColumns;

type SidePanelContextPropsType = {
  setToggledTableSettingsPanel: (toggledTableSettingsPanel: ToggledTableSettingsPanel | null) => void;
  toggledTableSettingsPanel: ToggledTableSettingsPanel | null;
};

export type SidePanelContextColumnsPropsType = {
  setToggledTableSettingsPanel: (toggledTableSettingsPanel: ToggledTableSettingsPanelColumns | null) => void;
  toggledTableSettingsPanel: ToggledTableSettingsPanelColumns | null;
};

export type SidePanelContextRowsPropsType = {
  setToggledTableSettingsPanel: (toggledTableSettingsPanel: ToggledTableSettingsPanelRows | null) => void;
  toggledTableSettingsPanel: ToggledTableSettingsPanelRows | null;
};

export const SidePanelProviderContext = React.createContext<SidePanelContextPropsType>({} as SidePanelContextPropsType);

interface DesignSettingsModelToggleProps {
  children: React.ReactNode;
}
export function SidePanelProvider({ children }: DesignSettingsModelToggleProps) {
  const [toggledTableSettingsPanel, setToggledTableSettingsPanel] = useState<ToggledTableSettingsPanel | null>(null);

  const contextValue: SidePanelContextPropsType = {
    toggledTableSettingsPanel: toggledTableSettingsPanel,
    setToggledTableSettingsPanel: setToggledTableSettingsPanel,
  };

  return <SidePanelProviderContext.Provider value={contextValue}>{children}</SidePanelProviderContext.Provider>;
}
