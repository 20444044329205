import { notification } from 'antd';
import DOMPurify from 'dompurify';
import './index.less';
import Icon from '../icon';
import { NotificationProps } from './interface';
import { Heading, Paragraph } from '../typography';
import { getIcon } from './NotificationIcon';

notification.config({
  maxCount: 3,
  placement: 'bottomRight',
  duration: 4.5,
});

export const openNotification = ({
  type,
  title,
  description,
  duration = 4.5,
  placement = 'bottomRight',
  customDescription,
}: NotificationProps): void => {
  const sanitizedHTML = DOMPurify.sanitize(customDescription);
  const noticeDescription = customDescription ? (
    <div className="ant-typography proposify-typography default-font size-sm" dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
  ) : (
    <Paragraph size="sm">{description}</Paragraph>
  );

  return notification[type]({
    message: <Heading level={5}>{title}</Heading>,
    description: noticeDescription,
    placement: placement,
    duration: duration,
    closeIcon: <Icon name="IcoCloseX" className="fog" title="Close" />,
    icon: getIcon(type),
  });
};
