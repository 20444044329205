import { List } from 'antd';
import { Link } from 'react-router-dom';
import { URLS } from 'utils/urls';
import './styles.less';
import usePreviewUrl from '../../../editor/usePreviewUrl';
import { ReactNode, useEffect, useState } from 'react';
import { Document } from 'services/documents/entities/Document';
import { NotifyObject, listUndoObserver } from '../listUndoObserver';
import { DuplicateDocumentAction } from './listActionItems/DuplicateDocumentAction';
import { TrashDocumentAction } from './listActionItems/TrashDocumentAction';
import { CINDER_URLS } from 'utils/cinder-urls';
import { BetaBadge } from '../beta-badge';
import { Chip, Divider } from '@mui/material';
import { useCurrentUser } from 'providers/UserProvider';
import { convertCreatedAtDateToUserTime, formatCreatedAtDate, isToday } from 'utils/dateHandlers';
import { Avatar } from 'components/avatar';
import { Tooltip } from 'components';

export type ListType = 'Documents' | 'Trash';

export type Props = {
  type: ListType;
  documentList: Document[];
  setDocumentList: (documents: Document[] | ((prev: Document[]) => Document[])) => Promise<void>;
  isLoading: boolean;
  listEmpty: ReactNode;
  currentPage: number;
  handleChangePage: (page: number) => void;
  action: {
    icon: ReactNode;
    message: string;
    tooltipText: string;
    onAction: (documentId: string) => Promise<void>;
    onUndo: (documentId: string) => Promise<void>;
  };
};
export function PipelineList({ type, documentList, setDocumentList, listEmpty, isLoading, action, currentPage, handleChangePage }: Props) {
  const [highlightItemId, setHighlightItemId] = useState('');
  const previewUrlPrefix = usePreviewUrl();
  const { data: currentUserInfo } = useCurrentUser();

  useEffect(() => {
    const observerId = listUndoObserver.subscribe({
      action: removeDocumentWhenUndoFromAnotherTab,
    });

    return () => {
      listUndoObserver.unsubscribe(observerId);
    };
  }, []);

  const removeDocumentWhenUndoFromAnotherTab = ({ listType, documentId }: NotifyObject) => {
    if (listType !== type) {
      setDocumentList((previousList) => previousList.filter((document) => document.id !== documentId));
    }
  };

  const handleOnListMouseEnter = (e: React.MouseEvent) => {
    const documentTile = e.currentTarget;
    const documentDetails = documentTile.querySelector('.document-details');
    const v3BetaBadgeChip = documentTile.querySelector('.v3-beta-badge');

    if (v3BetaBadgeChip) {
      documentDetails?.classList.add('hide');
    }

    if (!highlightItemId) {
      return;
    }
    setHighlightItemId('');
  };

  const handleOnListMouseLeave = (e: React.MouseEvent) => {
    const documentTile = e.currentTarget;
    const documentDetails = documentTile.querySelector('.document-details');
    const v3BetaBadgeChip = documentTile.querySelector('.v3-beta-badge');

    if (v3BetaBadgeChip) {
      documentDetails?.classList.remove('hide');
    }
  };

  const viewNewlyDuplicatedDocument = (itemId: string) => {
    if (currentPage === 1) {
      const targetDocument = document.getElementById(itemId);
      if (targetDocument) {
        targetDocument.scrollIntoView();
      }
    } else {
      handleChangePage(1);
      scroll(0, 0);
    }
  };

  const showDateOrTime = (createdAtDate: string) => {
    const userTimezone = currentUserInfo.timezone || 'UTC';

    if (isToday(createdAtDate)) {
      return convertCreatedAtDateToUserTime(createdAtDate, userTimezone);
    } else {
      return formatCreatedAtDate(createdAtDate);
    }
  };

  return (
    <List
      className="pipeline__list"
      loading={isLoading}
      dataSource={documentList}
      locale={{ emptyText: listEmpty }}
      renderItem={(item) => {
        const isEditorv3 = item.editorVersion === 3;
        let assignedUser = '';
        if (item.assigneeUserFirstName && item.assigneeUserLastName) {
          assignedUser = item.assigneeUserFirstName + ' ' + item.assigneeUserLastName;
        }

        const assignedUserProfileImage = assignedUser === currentUserInfo.fullName ? currentUserInfo.avatar : null;

        const assignedStandaloneContactFullName =
          item.prospectFirstName && item.prospectLastName && `${item.prospectFirstName} ${item.prospectLastName}`;

        const assignedProspect = item.companyName || assignedStandaloneContactFullName || '-';

        const tooltip = assignedUser;

        return (
          <Link
            to={isEditorv3 ? (item.isLocked ? previewUrlPrefix + item.id : URLS.editDocument(item.id)) : CINDER_URLS.viewSnapshot(item.id)}
            key={item.id}
            id={item.id}
            data-testid={`pipelineItem${item.id}`}
            className={`document-version-${item.editorVersion}`}
          >
            <List.Item
              className={`${item.status.toLocaleLowerCase()} ${highlightItemId === item.id ? ' item-highlight' : ''}`}
              onMouseEnter={handleOnListMouseEnter}
              onMouseLeave={handleOnListMouseLeave}
              data-testid={`list-item-${item.id}`}
            >
              <List.Item.Meta
                title={
                  <div className="document-title-container">
                    {isEditorv3 ? <BetaBadge /> : null}
                    <div className="document-title">{item.title}</div>
                  </div>
                }
                description={assignedProspect}
              ></List.Item.Meta>
              <div className="document-details">
                <div className="document-created-date">{showDateOrTime(item.created_at)}</div>
                <Tooltip placement={'bottom'} title={tooltip}>
                  <div className="assigned_user">
                    <Avatar
                      userFirstName={item.assigneeUserFirstName}
                      userLastName={item.assigneeUserLastName}
                      profileImage={assignedUserProfileImage}
                    />
                  </div>
                </Tooltip>
                <div className="document-status-container">
                  <Chip
                    label={item.status}
                    variant="filled"
                    className={`document-status-chip ${item.status.toLowerCase()}`}
                    size="small"
                    sx={{
                      '& .MuiChip-label': {
                        paddingLeft: 0,
                        paddingRight: 0,
                      },
                    }}
                  />
                </div>
              </div>

              <div className="document-actions">
                {type === 'Documents' && isEditorv3 ? (
                  <DuplicateDocumentAction
                    documentForDuplicate={item}
                    setDocumentList={setDocumentList}
                    setHighlightItemId={setHighlightItemId}
                    viewNewlyDuplicatedDocument={viewNewlyDuplicatedDocument}
                  />
                ) : null}
                {isEditorv3 ? (
                  <TrashDocumentAction
                    listType={type}
                    documentList={documentList}
                    documentToTrash={item}
                    setDocumentList={setDocumentList}
                    action={action}
                    isLoading={isLoading}
                  />
                ) : null}
              </div>
            </List.Item>
            <Divider className="document-divider" />
          </Link>
        );
      }}
    />
  );
}
