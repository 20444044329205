import { useTranslation } from 'react-i18next';

import { TableSettings } from '../../../component';
import SvgIcoTableRowPlugsAfter from 'components/icon/icons/IcoTableRowPlusAfter';
import SvgIcoTableRowPlugsBefore from 'components/icon/icons/IcoTableRowPlusBefore';
import SvgIcoTrash from 'components/icon/icons/IcoTrash';
import SvgIcoContentCopy from 'components/icon/icons/IcoContentCopy';

import { setActiveTableSettingsPanel } from '../../../../grid/reduxStore/blockStyleSettingsSlice';
import { TableRowTypes } from '../../../../../../muiTheme/MuiDataGrid';
import { useTableRowTypeChange } from './useTableRowTypeChange';
import { TableRowAddPositions, useTableRowManipulation } from './useTableRowManipulation';
import { useAppDispatch } from '../../../../grid/reduxStore/Store';

const RowSettings = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { rowType, handleRowTypeChange } = useTableRowTypeChange();
  const { handleRowAdd, handleRowDuplication, handleDeleteRow } = useTableRowManipulation();

  const handleBackButtonClick = () => {
    dispatch(setActiveTableSettingsPanel({ type: null }));
  };

  const buttons = [
    {
      icon: <SvgIcoTableRowPlugsAfter />,
      arialLabel: 'row-plus-after',
      fn: () => handleRowAdd(TableRowAddPositions.AFTER),
    },
    {
      icon: <SvgIcoTableRowPlugsBefore />,
      arialLabel: 'row-plus-before',
      fn: () => handleRowAdd(TableRowAddPositions.BEFORE),
    },
    {
      icon: <SvgIcoContentCopy />,
      arialLabel: 'content-copy',
      fn: () => handleRowDuplication(),
    },
    {
      icon: <SvgIcoTrash />,
      arialLabel: 'trash',
      fn: () => handleDeleteRow(),
    },
  ];

  return (
    <TableSettings
      iconButtons={buttons}
      handleBackButtonClick={handleBackButtonClick}
      handleSelectChange={handleRowTypeChange}
      helpText={t('document.grid.table.setting.update_select_row_type')}
      panelTitle={t('document.grid.table.setting.row_type')}
      selectId="select-row-type"
      selectOptions={TableRowTypes}
      selectValue={rowType}
      menuTitle={t('document.grid.table.setting.rows')}
      testId="table-row-settings"
    />
  );
};

export default RowSettings;
