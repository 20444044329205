import { useCurrentUser } from '../providers/UserProvider';
import { useLocation } from 'react-router-dom';
import { useIsUserTrial } from './useIsUserTrial';

enum PageList {
  getSettingsPage = '/settings/plan',
}

export function useSectionHeader() {
  const {
    data: { accountId },
  } = useCurrentUser();
  const isTrial = useIsUserTrial({ accountId, enabled: !!accountId });

  const location = useLocation();

  const shouldShowSectionHeader = (): boolean => {
    return !Object.values(PageList).includes(location.pathname as PageList) || !isTrial;
  };

  const shouldShowTopBar = (): boolean => {
    return !Object.values(PageList).includes(location.pathname as PageList);
  };

  return { shouldShowSectionHeader, shouldShowTopBar };
}
