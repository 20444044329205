import React, { useState } from 'react';
import { Menu, Layout, Drawer } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { Props } from './interface';
import { usePermissionsContextValue } from 'providers/PermissionsProvider';
import { getNavigationItemsWithPermissions } from 'services/permissions/helpers/NavigationItemsPermissions';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { desktopNavigationItems } from 'utils/navigationItems';
import MobileMenu from './mobileMenu';
import './styles.less';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { FeatureFlags } from 'utils/featureFlags';
import { constructCinderUrl } from 'utils/constructCinderUrl';
import { CINDER_URLS } from 'utils/cinder-urls';
import { Box, Chip, IconButton, List, ListItemText, Typography } from '@mui/material';
import IcoDoubleArrowLeft from '../icon/icons/IcoDoubleArrowLeft';

const { Sider } = Layout;

const Sidebar: React.FC<Props> = ({ mode }) => {
  const { userPermissions } = usePermissionsContextValue();

  const location = useLocation();

  const itemsWithPermissions = getNavigationItemsWithPermissions(desktopNavigationItems, userPermissions);

  const defaultSelectedKey = ['' + itemsWithPermissions.findIndex((item) => item.href === location.pathname)];

  const { isDesktop, isSmallerThanDesktop } = useMediaQuery();
  const isDesktopView = mode === 'fixed' && isDesktop;
  const isMobileView = mode === 'collapsible' && isSmallerThanDesktop;

  const evaluateV3Flags = useFeatureFlag([FeatureFlags.editorV3, FeatureFlags.documentsV3]);
  const hasAnyFlag = evaluateV3Flags[FeatureFlags.editorV3] || evaluateV3Flags[FeatureFlags.documentsV3];
  const hasEditorV3Flag = evaluateV3Flags[FeatureFlags.editorV3];
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const conditionallyUpdateNavigationItem = itemsWithPermissions.map((navigationItem) => {
    if (navigationItem.id === 'documents-list') {
      return {
        ...navigationItem,
        href: '/pipeline',
      };
    }
    if (navigationItem.title === 'Content Library' && hasEditorV3Flag) {
      return {
        ...navigationItem,
        href: '#',
      };
    }
    return navigationItem;
  });

  const drawerListContent = (
    <List sx={{ display: 'flex', flexDirection: 'column', gap: '12px', padding: '0 24px 0 24px' }}>
      <ListItemText>
        <a href={constructCinderUrl(CINDER_URLS.library)} data-testid="v2-library-nav-link">
          Content Library
        </a>
      </ListItemText>
      <ListItemText>
        <a href="/library" data-testid="library-nav-link">
          Content Library 3.0
        </a>
        <Chip
          label="Beta"
          size="small"
          style={{
            backgroundColor: '#7FD0FF',
            color: '#001E2D',
            fontFamily: 'proxima-nova',
            fontSize: '12px',
            fontWeight: '600',
            marginLeft: '4px',
          }}
        ></Chip>
      </ListItemText>
      <ListItemText>
        <Typography fontSize="14px" lineHeight="16px" letterSpacing="0.18px" color="#41484D" fontFamily="proxima-nova" fontWeight="500">
          We’ve improved the Content Library experience! Be the first to try the beta with your v3 documents.
        </Typography>
      </ListItemText>
    </List>
  );

  const handleContentLibraryIconClick = (navigationItem) => {
    if (navigationItem.title === 'Content Library' && evaluateV3Flags[FeatureFlags.editorV3]) {
      setIsDrawerOpen(true);
    }
  };

  if (isDesktopView) {
    return (
      <>
        <Sider width={56} defaultCollapsed collapsed collapsedWidth={56} data-testid="app-sidebar" className="app-sidebar">
          <Menu
            items={(hasAnyFlag ? conditionallyUpdateNavigationItem : itemsWithPermissions).map(
              ({ component, title, href, external, hidden }, i) => ({
                icon: React.createElement(component),
                label: external ? <a href={href}></a> : <Link to={href}></Link>,
                title,
                hidden,
                key: i,
                'data-testid': i,
                onClick: () => handleContentLibraryIconClick({ component, title, href }),
              })
            )}
            theme="dark"
            defaultSelectedKeys={defaultSelectedKey}
            mode="inline"
          />
          <Drawer
            onClose={() => setIsDrawerOpen(false)}
            open={isDrawerOpen}
            placement={'left'}
            style={{ marginLeft: '56px', width: '252px' }}
            push={false}
            headerStyle={{ display: 'none' }}
            bodyStyle={{ background: '#f3f3f6', padding: 0 }}
            mask={false}
            width={252}
          >
            <Box display="flex" justifyContent="flex-end" padding="8px">
              <IconButton onClick={() => setIsDrawerOpen(false)}>
                <IcoDoubleArrowLeft color="#41484D" />
              </IconButton>
            </Box>
            <Box padding="12px" display="flex" flexDirection="column">
              {drawerListContent}
            </Box>
          </Drawer>
        </Sider>
      </>
    );
  } else if (isMobileView) {
    return <MobileMenu />;
  } else {
    return <></>;
  }
};

export default Sidebar;
