import { Button, Tooltip, openNotification } from 'components';
import { FileCopyOutlined as FileCopyIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { closeToast, openToast } from 'components/toast';
import { deleteDocument } from 'services/proposal/repositories/implementations/ApiProposalRepository';
import { Document } from 'services/documents/entities/Document';
import useCreateDocumentMutation from 'hooks/useCreateDocumentMutation';
import { apiDocumentRepository } from 'services/repositories/implementations/ApiDocumentRepository';

export type Props = {
  setDocumentList: (documents: Document[] | ((prev: Document[]) => Document[])) => Promise<void>;
  documentForDuplicate: Document;
  setHighlightItemId: (highlightItemId: string) => void;
  viewNewlyDuplicatedDocument: (newlyDuplicatedDocumentId: string) => void;
};

export function DuplicateDocumentAction({ documentForDuplicate, setDocumentList, setHighlightItemId, viewNewlyDuplicatedDocument }: Props) {
  const { t } = useTranslation();
  const { createDocument } = useCreateDocumentMutation();

  const handleDuplicateUndo = async (duplicatedDocumentId: string) => {
    try {
      await deleteDocument(duplicatedDocumentId);
      setDocumentList((prevDocumentList) => {
        return prevDocumentList.filter(({ id }) => id !== duplicatedDocumentId);
      });
      openToast({
        message: t('pipeline.tabs.document_list.duplicate_undo_success_message'),
      });
    } catch (error) {
      openNotification({
        type: 'error',
        title: t('pipeline.error.undo_error_msg'),
        description: t('pipeline.error.error_description'),
        placement: 'top',
      });
    }
  };

  const handleDuplicateAction = async (document: Document) => {
    setDocumentList((documentList) => [document, ...documentList]);
  };

  const duplicateDocumentOnList = async (event: React.MouseEvent<HTMLButtonElement>, documentForDuplicate: Document) => {
    event.preventDefault();
    const documentTitle = documentForDuplicate.title;
    try {
      openToast({
        message: t('pipeline.tabs.document_list.duplicate_started_message', { documentTitle: documentTitle }),
        durationInSeconds: 0,
      });

      const fullDocument = await apiDocumentRepository.getDocumentById(documentForDuplicate.id);
      const duplicatedDocument = await createDocument({
        title: `Copy of ${fullDocument.title}`,
        price: fullDocument.price,
        copyFromContentId: fullDocument.contentId,
      });

      const duplicatedDocumentId = duplicatedDocument.documentId;

      const fullDuplicatedDocument = await apiDocumentRepository.getDocumentById(duplicatedDocumentId);

      const status = (fullDuplicatedDocument.status[0].toUpperCase() + fullDuplicatedDocument.status.slice(1).toLowerCase()) as
        | 'Won'
        | 'Lost'
        | 'Draft';
      await handleDuplicateAction({
        editorVersion: 3,
        id: fullDuplicatedDocument.id,
        title: fullDuplicatedDocument.title,
        status,
        isLocked: fullDuplicatedDocument.isLocked,
        created_at: new Date().toISOString(),
      });
      setHighlightItemId(duplicatedDocumentId);

      openToast({
        message: t('pipeline.tabs.document_list.duplicate_done_message', { documentTitle: documentTitle }),
        animationWhenReplacingToast: false,
        onUndo: async () => {
          await handleDuplicateUndo(duplicatedDocumentId);
        },
        onSecondaryAction: {
          text: t('toast.scroll'),
          onClick: () => viewNewlyDuplicatedDocument(duplicatedDocumentId),
        },
        onClose: () => setHighlightItemId(''),
      });
    } catch (error) {
      closeToast();
      openNotification({
        type: 'error',
        title: t('pipeline.error.duplicate_error_msg'),
        description: t('pipeline.error.error_description'),
        placement: 'top',
      });
    }
  };

  return (
    <Tooltip placement={'bottom'} title={t('pipeline.tabs.document_list.duplicate_tooltip')} className="pipeline-documents-tooltip">
      <Button
        icon={<FileCopyIcon />}
        variant="neutral"
        type="default"
        className="duplicate-button"
        data-testid="duplicate-button"
        onClick={(event) => {
          duplicateDocumentOnList(event, documentForDuplicate);
        }}
      ></Button>
    </Tooltip>
  );
}
