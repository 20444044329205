import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, Stack, Typography, Accordion, AccordionSummary, AccordionDetails, styled, Divider, Box } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/KeyboardArrowDown';

import { useMediaQuery } from 'hooks/useMediaQuery';
import { FAQItemProps, FAQProps } from './interface';

const FAQ = ({ pausable, cancelable }: FAQProps) => {
  const { t } = useTranslation();
  const { isMobile } = useMediaQuery();

  const FAQItems = styled(Stack)(({ theme }) => ({
    borderRadius: isMobile ? 0 : theme.spacing(4),
    backgroundColor: '#ffffff',
    boxShadow: 'none',
    padding: isMobile ? theme.spacing(0, 2, 1) : theme.spacing(1, 3),
    margin: theme.spacing(4, 0, 3),
  }));

  const FAQAccordion = styled(Accordion)(() => ({
    boxShadow: 'none',
    background: 'none',
    width: '100%',
    '&.Mui-expanded': {
      margin: 0,
    },
    '&.Mui-expanded:before': {
      color: 'transparent',
      background: 'transparent',
      display: 'none',
    },
  }));

  const FAQAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    minHeight: '64px',
    padding: theme.spacing(2, 0),
    gap: theme.spacing(3),
    '& .MuiAccordionSummary-content': {
      margin: theme.spacing(0),
      padding: theme.spacing(0),
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: theme.spacing(0),
      padding: theme.spacing(0),
    },
  }));

  const FAQAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    margin: theme.spacing(0),
    padding: theme.spacing(0, 0, 2, 0),
  }));

  const FAQLink = styled(Link)(() => ({
    fontWeight: '400',
    '&:-webkit-any-link': { fontWeight: '400' },
  }));

  const renderFAQLink = (href: string) => {
    return <FAQLink href={href} rel="noopener" target="_blank" color="inherit" variant="subtitle1" />;
  };

  const FAQItem: React.FC<FAQItemProps> = ({ id, question, children, includeProfessionalServices = false }) => (
    <FAQAccordion>
      <FAQAccordionSummary expandIcon={<ArrowDownwardIcon />} aria-controls={`FAQ-${id}-content`} id={`FAQ-${id}-header`}>
        <Typography variant="h6">{question}</Typography>
      </FAQAccordionSummary>
      <FAQAccordionDetails>
        <Typography variant="body1" component="div">
          {children}
          {includeProfessionalServices && (
            <Trans
              i18nKey="settings.plan.faq.professional_services"
              components={{
                l: renderFAQLink('https://support.proposify.com/'),
              }}
            />
          )}
        </Typography>
      </FAQAccordionDetails>
    </FAQAccordion>
  );

  return (
    <Box sx={{ padding: 0 }}>
      <Stack gap={2} direction="column" alignItems="center">
        <Typography
          variant="h4"
          sx={{
            fontSize: isMobile ? '1.4rem' : '1.6rem',
          }}
        >
          {t('settings.plan.faqs')}
        </Typography>
        <Typography variant="subtitle1" component="div">
          {
            <Trans
              i18nKey="settings.plan.faq.subtitle"
              components={{
                l: renderFAQLink('https://support.proposify.com/'),
              }}
            />
          }
        </Typography>
      </Stack>

      <FAQItems direction="column" alignItems="flex-start" divider={<Divider orientation="horizontal" flexItem />}>
        <FAQItem id="item1" question={t('settings.plan.faq.item_1.question')}>
          <React.Fragment>
            <p>{t('settings.plan.faq.item_1.answer.p_1')}</p>
            <p>{t('settings.plan.faq.item_1.answer.p_2')}</p>
          </React.Fragment>
        </FAQItem>

        <FAQItem id="item2" question={t('settings.plan.faq.item_2.question')} includeProfessionalServices={true}>
          <React.Fragment>
            <p>{t('settings.plan.faq.item_2.answer.p_1')}</p>
            <p>{t('settings.plan.faq.item_2.answer.p_2')}</p>
          </React.Fragment>
        </FAQItem>

        <FAQItem id="item3" question={t('settings.plan.faq.item_3.question')} includeProfessionalServices={true}>
          <React.Fragment>
            <p>{t('settings.plan.faq.item_3.answer.p_1')}</p>
            <p>{t('settings.plan.faq.item_3.answer.p_2')}</p>
          </React.Fragment>
        </FAQItem>

        <FAQItem id="item4" question={t('settings.plan.faq.item_4.question')} includeProfessionalServices={true}>
          <React.Fragment>
            <p>{t('settings.plan.faq.item_4.answer.p_1')}</p>
            <p>{t('settings.plan.faq.item_4.answer.p_2')}</p>
          </React.Fragment>
        </FAQItem>

        <FAQItem id="item5" question={t('settings.plan.faq.item_5.question')}>
          <div>
            <p>{t('settings.plan.faq.item_5.answer.p_1')}</p>
            <p>
              {
                <Trans
                  i18nKey="settings.plan.faq.item_5.answer.p_2"
                  components={{
                    1: renderFAQLink('https://links.proposify.com/support/knowledge-base'),
                    3: renderFAQLink('https://www.proposify.com/pricing'),
                  }}
                />
              }
            </p>
          </div>
        </FAQItem>

        <FAQItem id="item6" question={t('settings.plan.faq.item_6.question')}>
          <p>
            {
              <Trans
                i18nKey="settings.plan.faq.item_6.answer.p_1"
                components={{
                  1: renderFAQLink('https://www.proposify.com/security'),
                }}
              />
            }
          </p>
        </FAQItem>

        <FAQItem id="item7" question={t('settings.plan.faq.item_7.question')}>
          <p>{t('settings.plan.faq.item_7.answer.p_1')}</p>
        </FAQItem>

        <FAQItem id="item8" question={t('settings.plan.faq.item_8.question')}>
          <p>
            {
              <Trans
                i18nKey="settings.plan.faq.item_8.answer.p_1"
                components={{
                  1: renderFAQLink('https://www.proposify.com/pricing'),
                }}
              />
            }
          </p>
        </FAQItem>

        <FAQItem id="item9" question={t('settings.plan.faq.item_9.question')}>
          <p>{t('settings.plan.faq.item_9.answer.p_1')}</p>
        </FAQItem>

        <FAQItem id="item10" question={t('settings.plan.faq.item_10.question')}>
          <p>{t('settings.plan.faq.item_10.answer.p_1')}</p>
        </FAQItem>
        {cancelable && (
          <FAQItem id="item11" question={t('settings.plan.faq.item_11.question')}>
            <p>
              {
                <Trans
                  i18nKey="settings.plan.faq.item_11.answer.p_1"
                  components={{
                    1: renderFAQLink(`${process.env.REACT_APP_LEGACY_APP_URL}/settings/cancel`),
                  }}
                />
              }
            </p>
          </FAQItem>
        )}
        {pausable && (
          <FAQItem id="item12" question={t('settings.plan.faq.item_12.question')}>
            <p>
              {
                <Trans
                  i18nKey="settings.plan.faq.item_12.answer.p_1"
                  components={{
                    1: renderFAQLink(`${process.env.REACT_APP_LEGACY_APP_URL}/settings/status`),
                  }}
                />
              }
            </p>
          </FAQItem>
        )}
      </FAQItems>
    </Box>
  );
};

export default FAQ;
