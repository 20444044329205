import { useTranslation } from 'react-i18next';
import './styles.less';
import { Typography, Box, Dialog, Card, CardContent, Button, IconButton, Chip, Avatar, Stack } from '@mui/material';
import CloseX from '../../../../components/icon/icons/IcoCloseX';
import Approval from '../../../../components/icon/icons/IcoApproval';
import TextField from '../../../../components/icon/icons/IcoTextFields';
import Image from '../../../../components/icon/icons/IcoImage';
import Table from '../../../../components/icon/icons/IcoTable';
import PictureAsPdf from '../../../../components/icon/icons/IcoPictureAsPdf';

import { openNotification } from 'components';
import { useNavigate } from 'react-router-dom';

import { ApiEditorRepository } from 'services/repositories/implementations/ApiEditorRepository';
import { URLS } from 'utils/urls';
import { apiContentLibraryRepository } from 'services/repositories/implementations/ApiContentLibraryRepository';

interface Props {
  open: boolean;
  handleClose: () => void;
}

export function CreateAssetModal({ open, handleClose }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const editorRepository = new ApiEditorRepository();

  const handleAddAsset = async () => {
    try {
      const content = await editorRepository.createContent('TEMPLATE');
      const contentId = content.id;
      const asset = await apiContentLibraryRepository.create({
        type: 'TEMPLATE',
        content_id: contentId,
      });
      navigate(URLS.templateEdit(asset.id));
    } catch (error) {
      openNotification({
        type: 'error',
        title: t('library.error.create_error_msg'),
        description: t('library.error.general_error_description'),
        placement: 'top',
      });
    }
  };

  return (
    <Dialog
      className="new-asset-modal"
      data-testid="new-asset-modal"
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { borderRadius: '28px', maxWidth: '706px' } }}
    >
      <Box className="modal-container" display="flex" flexDirection="column" sx={{ m: '24px' }}>
        <Box display="flex">
          <Box>
            <Typography className="asset-modal-title">{t('library.new_asset_modal_title')}</Typography>
            <Typography className="asset-modal-text">{t('library.modal_supporting_text')}</Typography>
          </Box>
          <Box>
            <IconButton
              className="asset-modal-header-close-button"
              data-testid="asset-modal-header-close-button"
              onClick={handleClose}
              disableRipple
            >
              <CloseX />
            </IconButton>
          </Box>
        </Box>
        <Box display="flex" gap="24px" sx={{ pt: '24px' }}>
          <Card className="asset-card template-card">
            <CardContent className="asset-card-content">
              <Avatar className="asset-icon template-icon">
                <Approval />
              </Avatar>
              <Typography className="asset-card-title">{t('library.template')}</Typography>
              <Typography className="asset-card-text">{t('library.template_supporting_text')}</Typography>
            </CardContent>
          </Card>
          <Card className="asset-card other-assets-card">
            <CardContent className="asset-card-content">
              <Stack direction="row" spacing={-1}>
                <Avatar className="asset-icon other-assets-icon">
                  <Image />
                </Avatar>
                <Avatar className="asset-icon other-assets-icon">
                  <TextField />
                </Avatar>
                <Avatar className="asset-icon other-assets-icon">
                  <PictureAsPdf />
                </Avatar>
                <Avatar className="asset-icon other-assets-icon">
                  <Table />
                </Avatar>
              </Stack>
              <Typography className="asset-card-title">{t('library.other_assets')}</Typography>
              <Chip className="other-assets-chip" label={t('library.coming_soon')}></Chip>
            </CardContent>
          </Card>
        </Box>
        <Box display="flex" justifyContent="flex-end" gap="8px" sx={{ pt: '24px' }}>
          <Button className="asset-modal-button asset-modal-cancel-button" onClick={handleClose} disableRipple>
            {t('cancel')}
          </Button>
          <Button className="asset-modal-button asset-modal-create-button" disableRipple onClick={handleAddAsset}>
            {t('create')}{' '}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}
