import { Grid, Box, InputAdornment } from '@mui/material';
import { SecondaryMenuItemTitle } from './SecondaryMenuTitles';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';

type TextInputBoxSingleColProps = {
  title?: string;
  descriptionText?: string;
  onChange: (val: string) => void;
  onBlur?: (val: string) => void;
  startAdornmentText?: string;
  value?: string;
  error?: string;
};

export const TextInputBoxSingleCol = ({
  onChange,
  onBlur,
  title = 'title',
  descriptionText = 'decText',
  startAdornmentText = '',
  value,
  error,
}: TextInputBoxSingleColProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    onChange(newValue);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (!onBlur) return;
    const newValue = event.target.value;
    onBlur(newValue);
  };

  const isError = !!error;

  return (
    <Grid container direction="column" rowGap={1}>
      <SecondaryMenuItemTitle>{title}</SecondaryMenuItemTitle>
      <Grid item width={'100%'}>
        <FormControl variant="outlined" size="small" fullWidth>
          <OutlinedInput
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            error={isError}
            value={value}
            startAdornment={<InputAdornment position="start">{startAdornmentText}</InputAdornment>}
          />
        </FormControl>
      </Grid>
      <Box component="p" fontSize={12} lineHeight={1.5} letterSpacing={0.18} color={isError ? 'red' : '#353636'}>
        {isError ? error : descriptionText}
      </Box>
    </Grid>
  );
};
