import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { UserPermissionsRepository } from 'services/permissions/repositories/UserPermissionsRepository';
import { UserPermission } from 'services/permissions/entities/UserPermissions';
import { QueryKeys } from 'utils/queryKeys';

interface Props {
  children: ReactNode;
  repository: UserPermissionsRepository;
}

type ProviderValue = {
  userPermissions: UserPermission[];
};

export const PermissionsContext = createContext<ProviderValue>({ userPermissions: [] });

const PermissionsProvider: React.FunctionComponent<Props> = ({ children, repository }) => {
  const [userPermissions, setUserPermissions] = useState<UserPermission[]>([]);

  const { data } = useQuery({
    queryKey: QueryKeys.currentUserPermissions,
    queryFn: () => repository.getUserPermissions(),
  });

  useEffect(() => {
    setUserPermissions(data ?? []);
  }, [data]);

  const value = useMemo(
    () => ({
      userPermissions,
    }),
    [userPermissions]
  );

  return <PermissionsContext.Provider value={value}>{children}</PermissionsContext.Provider>;
};

export const usePermissionsContextValue = () => {
  const value = useContext(PermissionsContext);
  if (!value) {
    throw Error('usePermissionsContextValue hook must be used inside PermissionsProvider');
  }

  return value;
};

export default PermissionsProvider;
