import { openNotification } from 'components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Document } from 'services/documents/entities/Document';
import { apiDocumentRepository } from 'services/repositories/implementations/ApiDocumentRepository';

type Params = {
  filterTrash?: boolean;
  filterTerm?: string;
  filterStatus?: string;
};

const filterBySearchTerm = (documentList: Document[], filterTerm: string) => {
  const lowerCaseFilterTerm = filterTerm.toLowerCase();
  return (documentList = documentList.filter((document) => {
    return (
      document.companyName?.toLowerCase().includes(lowerCaseFilterTerm) ||
      document.title.toLowerCase().includes(lowerCaseFilterTerm) ||
      document.assigneeUserFirstName?.toLowerCase().includes(lowerCaseFilterTerm) ||
      document.assigneeUserLastName?.toLowerCase().includes(lowerCaseFilterTerm)
    );
  }));
};

const filterByStatus = (documentList: Document[], filterStatus: string) => {
  return (documentList = documentList.filter((item) => filterStatus.includes(item.status)));
};

const filterTrashDocuments = (documentList: Document[], filterTrash: boolean) => {
  if (filterTrash) {
    const currentDate = Date.now();
    return (documentList = documentList.filter((document) => {
      if (document.deleted_at) {
        const deletedAtDate = Date.parse(document.deleted_at);
        return deletedAtDate > currentDate;
      } else {
        return false;
      }
    }));
  } else {
    return (documentList = documentList.filter((document) => !document.deleted_at));
  }
};

export function useDocumentListQuery({ filterTrash = false, filterTerm = '', filterStatus = '' }: Params) {
  const { t } = useTranslation();
  const [originalDocumentList, setOriginalDocumentList] = useState<Document[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchDocumentList = async () => {
    try {
      const response = await apiDocumentRepository.list();
      const fetchedDocumentList = response.documents;

      setOriginalDocumentList(fetchedDocumentList);
      setIsLoading(false);
    } catch (error) {
      openNotification({
        type: 'error',
        title: t('pipeline.error.error_title'),
        description: t('pipeline.error.error_description'),
        placement: 'top',
      });
    }
  };

  useEffect(() => {
    fetchDocumentList();
  }, []);

  let documentList = originalDocumentList;

  if (filterTerm) {
    documentList = filterBySearchTerm(documentList, filterTerm);
  }

  if (filterStatus && !filterStatus.includes('All')) {
    documentList = filterByStatus(documentList, filterStatus);
  }

  documentList = filterTrashDocuments(documentList, filterTrash);

  const setDocumentList = async (param: Document[] | ((prev: Document[]) => Document[])) => {
    if (typeof param === 'function') {
      setOriginalDocumentList(param(documentList));
    } else {
      setOriginalDocumentList(param);
    }
  };

  const refreshDocumentList = async () => {
    setIsLoading(true);
    await fetchDocumentList();
  };

  return {
    isLoadingDocumentList: isLoading,
    documentList: documentList || [],
    setDocumentList,
    refreshDocumentList: refreshDocumentList,
  };
}
