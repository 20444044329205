import React from 'react';
import { Box } from '@mui/material';
import { CreateAssetButton } from './components/create-asset-button';
import { AssetList } from './components/asset-list';
import { useContentLibraryListQuery } from 'hooks/useContentLibraryListQuery';
import { useNavigate } from 'react-router-dom';
import { Asset } from 'services/library/entities/Asset';
import { URLS } from 'utils/urls';
import EmptyState from 'components/emptyState';
import { useTranslation } from 'react-i18next';
import NoAssets from '../../images/empty-state/No_Assets.svg';
import { FenixThemeProvider } from 'providers/FenixThemeProvider';

const LibraryPage: React.FC = () => {
  const { visibleAssetsList, isLoading, isAssetListLoaded } = useContentLibraryListQuery();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onAssetClickNavigateToTemplate = (asset: Asset) => {
    navigate(URLS.templateEdit(asset.id));
  };

  return (
    <FenixThemeProvider>
      <Box display="flex" height="100%" className="content-library-page-container" data-testid="content-library-page-container">
        <Box display="flex" justifyContent="flex-end" width="244px">
          <CreateAssetButton variant="extended" />
        </Box>
        <Box flexGrow={1} borderRadius="28px" marginLeft="24px">
          {isAssetListLoaded && !isLoading && visibleAssetsList.length === 0 ? (
            <Box height="100%" marginBottom="100px">
              <EmptyState
                headerText={t('library.empty_state.header')}
                primaryGraphic={NoAssets}
                supportingText={t('library.empty_state.supporting_text')}
                primaryActionButton={<CreateAssetButton variant="contained" />}
              />
            </Box>
          ) : (
            <Box>
              <AssetList onAssetClick={onAssetClickNavigateToTemplate} />
            </Box>
          )}
        </Box>
      </Box>
    </FenixThemeProvider>
  );
};

export default LibraryPage;
