import './styles.less';

export type Props = {
  userFirstName?: string | null;
  userLastName?: string | null;
  profileImage?: string | null;
};

function calculateInitialsHash(initials: string) {
  let initialsHash = 0;
  for (let i = 0; i < initials.length; i++) {
    initialsHash = (Math.imul(31, initialsHash) + initials.charCodeAt(i)) | 0;
  }
  return initialsHash;
}

function assignAvatarColor(initials: string) {
  const colors = [
    'color-primary70',
    'color-primary80',
    'color-primary90',
    'color-primary95',
    'color-secondary70',
    'color-secondary80',
    'color-secondary90',
    'color-secondary95',
  ];
  const initialsHash = calculateInitialsHash(initials);
  const colorIndex = initialsHash % colors.length;
  return colors[colorIndex];
}

export function Avatar({ userFirstName, userLastName, profileImage }: Props) {
  if (!userFirstName || !userLastName) {
    return null;
  }
  const userInitials = `${userFirstName?.[0].toUpperCase()}${userLastName?.[0].toUpperCase()}`;
  const userProfileImage = !profileImage?.includes('img/blank.gif') ? profileImage : null;
  const avatarBackgroundColor = !userProfileImage ? assignAvatarColor(userInitials) : '';
  return (
    <span className={`avatar ${avatarBackgroundColor}`} data-testid="avatar-button">
      {userProfileImage ? <img src={userProfileImage} alt="Avatar Profile Image" className="avatar-image" /> : userInitials}
    </span>
  );
}
