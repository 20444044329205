import './styles.less';
import { TablePagination as MuiTablePagination } from '@mui/material';
import { useState } from 'react';

type Props = {
  totalDocuments: number;
  onPageChange: (page: number) => void;
  currentPage: number;
};

export function Pagination({ totalDocuments, onPageChange, currentPage }: Props) {
  const [page, setPage] = useState(currentPage - 1);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
    onPageChange(newPage + 1);
  };

  return (
    <MuiTablePagination
      component="div"
      count={totalDocuments}
      page={!totalDocuments || totalDocuments <= 0 ? 0 : page}
      onPageChange={handleChangePage}
      rowsPerPage={50}
      rowsPerPageOptions={[]}
      data-testid="pagination-toolbar"
    />
  );
}
