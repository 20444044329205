import { v4 as uuidv4 } from 'uuid';
import { ListType } from '../list/list';

export type NotifyObject = {
  listType: ListType;
  documentId: string;
};

type Observer = {
  action: (object: NotifyObject) => void;
};

const observers: Observer[] = [];

const subscribe = ({ action }: Observer): string => {
  const id = uuidv4();
  observers[id] = { action };
  return id;
};

const unsubscribe = (id: string) => {
  delete observers[id];
};

const notify = (object: NotifyObject) => {
  Object.keys(observers).forEach((key) => {
    observers[key].action(object);
  });
};

export const listUndoObserver = {
  subscribe,
  unsubscribe,
  notify,
};
