import { Icon } from 'components';
import { useDocumentListQuery } from 'hooks/useDocumentListQuery';
import { PipelineList } from '../../list';
import { useTranslation } from 'react-i18next';
import { moveDocumentToTrash } from 'services/proposal/repositories/implementations/ApiProposalRepository';
import { restoreDocumentFromTrash } from 'services/proposal/repositories/implementations/ApiProposalRepository';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Snackbar } from 'components/snackbar';
import { Button, Input } from 'antd';
import './styles.less';
import { SearchEmptyResult } from './search-empty-result';
import { trackEvents } from 'utils/tracking';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Check } from '@mui/icons-material';
import { Pagination } from '../../pagination';
import { DocumentsListEmptyState } from '../../documents-list-empty-state';

const TERM_QUERY_PARAM = 'term';
const STATUS_QUERY_PARAM = 'status';
const PAGE_QUERY_PARAM = 'page';

type HandleQueryParamsProps = {
  filterTerm?: string;
  filterStatus?: string;
  filterPage?: string;
};

export function ListDocuments() {
  const { t } = useTranslation();

  const pagePathname = window.location.pathname;
  const newQueryParameters = new URLSearchParams();
  const [currentQueryParams, setQueryParams] = useSearchParams();
  const currentSearchTerm = decodeURIComponent(currentQueryParams.get(TERM_QUERY_PARAM) ?? '');
  const currentStatusFilter = decodeURIComponent(currentQueryParams.get(STATUS_QUERY_PARAM) ?? '');
  const currentPageFilter = decodeURIComponent(currentQueryParams.get(PAGE_QUERY_PARAM) ?? '');
  const { documentList, isLoadingDocumentList, setDocumentList } = useDocumentListQuery({
    filterTrash: false,
    filterTerm: currentSearchTerm,
    filterStatus: currentStatusFilter,
  });
  const documentStatuses = ['Draft', 'Approval', 'Approved', 'Sent', 'Viewed', 'Unsigned', 'Lost', 'Won'];
  const [selectedItemStatus, setSelectedItemStatus] = useState(currentStatusFilter ? currentStatusFilter.split(',') : ['All']);
  const [currentPage, setCurrentPage] = useState(parseInt(currentPageFilter) ? parseInt(currentPageFilter) : 1);
  const documentsPerPage = 50;
  const indexOfLastDoc = currentPage * documentsPerPage;
  const indexOfFirstDoc = indexOfLastDoc - documentsPerPage;
  const paginatedDocs = documentList.slice(indexOfFirstDoc, indexOfLastDoc);
  const lastPage = Math.ceil(documentList.length / documentsPerPage);

  useEffect(() => {
    return () => {
      clearQueryParamsWhenChangingTabs();
    };
  }, []);

  const clearQueryParamsWhenChangingTabs = () => {
    const isRedirectingToAnotherPage = window.location.pathname !== pagePathname;
    if (isRedirectingToAnotherPage) {
      return;
    }
    newQueryParameters.delete(TERM_QUERY_PARAM);
    setQueryParams(newQueryParameters);
  };

  const sendUserToFirstPage = () => {
    if (currentPage !== 1) {
      handleChangePage(1);
    }
  };

  const handleSearch = (value: string) => {
    if (value === currentSearchTerm) {
      return;
    }

    sendUserToFirstPage();
    handleQueryParams({ filterTerm: value, filterPage: '1' });
  };

  const handleStatusFilter = (value: string) => {
    if (value === currentStatusFilter) {
      return;
    }
    sendUserToFirstPage();
    handleQueryParams({ filterStatus: value, filterPage: '1' });
  };

  const handlePageFilter = (value: string) => {
    if (value === currentPageFilter) {
      return;
    }
    handleQueryParams({ filterPage: value });
  };

  const handleQueryParams = ({
    filterTerm = currentSearchTerm,
    filterStatus = currentStatusFilter,
    filterPage = currentPageFilter,
  }: HandleQueryParamsProps) => {
    if (filterTerm === '') {
      newQueryParameters.delete(TERM_QUERY_PARAM);
    } else {
      newQueryParameters.append(TERM_QUERY_PARAM, encodeURIComponent(filterTerm));
      trackEvents('documents_search', { searchTerm: filterTerm });
    }

    if (filterStatus === '') {
      newQueryParameters.delete(STATUS_QUERY_PARAM);
    } else {
      newQueryParameters.set(STATUS_QUERY_PARAM, encodeURIComponent(filterStatus));
    }

    if (filterPage === '') {
      newQueryParameters.delete(PAGE_QUERY_PARAM);
    } else {
      newQueryParameters.set(PAGE_QUERY_PARAM, encodeURIComponent(filterPage));
    }

    setQueryParams(newQueryParameters);
  };

  const handleMoveToTrashAction = async (documentId: string) => {
    await moveDocumentToTrash(documentId);
  };

  const handleMoveToTrashUndo = async (documentId: string) => {
    await restoreDocumentFromTrash(documentId);
  };

  const handleStatusButtonOnChangeClick = (event, selectedStatus: string[]) => {
    const clickedStatus = event.target.value;

    if (clickedStatus === 'All') {
      setSelectedItemStatus(() => {
        handleStatusFilter('');
        return ['All'];
      });
    } else if (selectedItemStatus.length === 1 && currentStatusFilter === clickedStatus) {
      setSelectedItemStatus(['All']);
      handleStatusFilter('');
    } else {
      const filterSelectedStatus = selectedStatus.filter((status) => status !== 'All');
      setSelectedItemStatus(filterSelectedStatus);
      handleStatusFilter(filterSelectedStatus.join(','));
    }
  };

  const handleChangePage = (page: number) => {
    setCurrentPage(page);
    handlePageFilter(page.toString());
  };

  const clearStatusFilters = () => {
    newQueryParameters.delete(TERM_QUERY_PARAM);
    newQueryParameters.delete(STATUS_QUERY_PARAM);
    newQueryParameters.delete(PAGE_QUERY_PARAM);
    setQueryParams(newQueryParameters);
    setSelectedItemStatus(['All']);
  };

  return (
    <>
      <div className="documents-list-search-bar-area">
        <Input.Search
          defaultValue={currentSearchTerm}
          addonBefore={<Button className="documents-list-search-button" icon={<Icon name="IcoSearch24Px" />} />}
          placeholder={t('pipeline.tabs.search.inputPlaceholder')}
          className="documents-list-search-bar"
          bordered={false}
          allowClear={{ clearIcon: <Button icon={<Icon name="IcoCloseX" />} /> }}
          onBlur={(event) => handleSearch(event.target.value)}
          onSearch={(event) => handleSearch(event)}
          data-testid="pipelineSearchInput"
        ></Input.Search>
      </div>
      {currentSearchTerm && (
        <Snackbar message={t('pipeline.tabs.search.searchResults', { count: documentList.length, term: currentSearchTerm })} />
      )}
      <div className="documents-list-container">
        {documentList.length > 0 && currentPage <= lastPage && (
          <Pagination key={currentPage} totalDocuments={documentList.length} onPageChange={handleChangePage} currentPage={currentPage} />
        )}
        <PipelineList
          type="Documents"
          documentList={paginatedDocs}
          setDocumentList={setDocumentList}
          isLoading={isLoadingDocumentList}
          listEmpty={
            currentSearchTerm ? (
              <SearchEmptyResult />
            ) : (
              <DocumentsListEmptyState selectedItemStatus={selectedItemStatus} clearStatusFilters={clearStatusFilters} />
            )
          }
          handleChangePage={handleChangePage}
          currentPage={currentPage}
          action={{
            message: t('pipeline.tabs.document_list.action_message'),
            tooltipText: t('pipeline.tabs.document_list.action_tooltip'),
            onAction: handleMoveToTrashAction,
            onUndo: handleMoveToTrashUndo,
            icon: <Icon name="IcoTrash" />,
          }}
        />
        <ToggleButtonGroup
          className="status-filter-bar"
          value={selectedItemStatus}
          onChange={handleStatusButtonOnChangeClick}
          data-testid="status-filter-bar"
        >
          <ToggleButton className="status-button" value="All" selected={selectedItemStatus.includes('All')}>
            {selectedItemStatus.includes('All') && <Check fontSize="small" />}
            All
          </ToggleButton>
          {documentStatuses.map((status) => (
            <ToggleButton className="status-button" key={status} value={status} selected={selectedItemStatus.includes(status)}>
              {selectedItemStatus.includes(status) && <Check fontSize="small" />}
              {status}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </div>
    </>
  );
}
