import { notification } from 'antd';
import './styles.less';
import { Paragraph, Icon } from 'components';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';

type SecondaryAction = {
  text: string;
  onClick: () => void;
};

type Props = {
  message: string;
  onClose?: () => void;
  onUndo?: () => void;
  durationInSeconds?: number;
  animationWhenReplacingToast?: boolean;
  onSecondaryAction?: SecondaryAction;
};

const toastKey = 'customToast';

export const closeToast = () => {
  notification.close(toastKey);
};

export const openToast = ({
  message,
  onClose,
  onUndo,
  durationInSeconds = 5,
  animationWhenReplacingToast = true,
  onSecondaryAction,
}: Props) => {
  const notificationId = uuidv4();
  const onCloseTimeout = setTimeout(() => {
    if (durationInSeconds === 0) {
      return;
    }

    if (onClose) onClose();
    forceCloseWhenAutoDismissFail();
  }, durationInSeconds * 1000 + 200);
  /*the extra time for the timeout is to avoid this method to be called simultaneously
    with the onClose, making the call twice. This way we can clear it with more control
  */

  const forceCloseWhenAutoDismissFail = () => {
    const elements = document.getElementsByClassName(`toast-notification-${notificationId}`);
    if (elements.length === 0) {
      return;
    }

    notification.close(toastKey);
  };

  const handleUndo = () => {
    clearTimeout(onCloseTimeout);
    if (onUndo) onUndo();
    notification.close(toastKey);
  };

  const handleClose = () => {
    clearTimeout(onCloseTimeout);
    if (onClose) onClose();
  };

  const destroyNotification = () => {
    notification.destroy();
    const elements = document.getElementsByClassName('custom-toast');
    while (elements.length > 0) {
      elements[0].parentNode?.removeChild(elements[0]);
    }
  };

  const UndoButton = ({ onUndo }: { onUndo: () => void }) => {
    const { t } = useTranslation();

    return (
      <button className="toast-undo" onClick={onUndo}>
        {t('toast.undo')}
      </button>
    );
  };

  const SecondaryActionButton = ({ onSecondaryAction }: { onSecondaryAction?: SecondaryAction }) => {
    return (
      <button className="toast-secondary-action-btn" onClick={onSecondaryAction?.onClick}>
        {onSecondaryAction?.text}
      </button>
    );
  };

  if (animationWhenReplacingToast) {
    destroyNotification();
  }
  notification.open({
    className: `custom-toast toast-notification-${notificationId}`,
    placement: 'bottomLeft',
    props: {
      role: 'notification',
    },
    duration: durationInSeconds,
    key: toastKey,
    bottom: 40,
    message: <Paragraph size="sm">{message}</Paragraph>,
    closeIcon: <Icon name="IcoCloseX" className="toast-close" title="Close" />,
    btn: (
      <>
        {onUndo && <UndoButton onUndo={handleUndo} />}
        {onSecondaryAction && <SecondaryActionButton onSecondaryAction={onSecondaryAction} />}
      </>
    ),
    onClose: handleClose,
  });
};
