import { Paper } from '@mui/material';
import prismatic, { BooleanOperator, TermOperator } from '@prismatic-io/embedded';
import { useEffect } from 'react';
import usePrismaticAuth from './usePrismaticAuth';
import { Container, styled } from '@mui/material';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { FeatureFlags } from 'utils/featureFlags';

const embeddedDivId = 'embedded-designer-div';
const EmbeddedDesignerWrapper = styled(Container)(() => ({
  height: '70vh',
  width: '100%',
}));

export const PrismaticPage = (): JSX.Element => {
  const { authenticated, userinfo } = usePrismaticAuth();
  const { allowEmbeddedDesigner } = userinfo?.authenticatedUser.customer || { allowEmbeddedDesigner: false };
  const featureFlags = useFeatureFlag([FeatureFlags.prismatic]);
  const isPrismaticEnabled = featureFlags[FeatureFlags.prismatic];

  useEffect(() => {
    if (authenticated && allowEmbeddedDesigner) {
      prismatic.showIntegrations({
        selector: `#${embeddedDivId}`,
        theme: 'LIGHT',
        filters: {
          components: {
            filterQuery: [BooleanOperator.and, [TermOperator.notEqual, 'key', 'ftp'], [TermOperator.notEqual, 'key', 'sftp']],
          },
        },
      });
    }
  }, [authenticated, allowEmbeddedDesigner]);

  return (
    isPrismaticEnabled && (
      <Paper data-testid="my-embedded-builder-div" elevation={1} sx={{ marginLeft: '52px', borderRadius: '8px', padding: 2 }}>
        <EmbeddedDesignerWrapper id={embeddedDivId} maxWidth={false} disableGutters />
      </Paper>
    )
  );
};
