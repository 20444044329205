import { Heading, Icon, openNotification } from 'components';
import { Snackbar } from 'components/snackbar';
import { PipelineList } from '../../list';
import { useDocumentListQuery } from 'hooks/useDocumentListQuery';
import { restoreDocumentFromTrash } from 'services/proposal/repositories/implementations/ApiProposalRepository';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { moveDocumentToTrash } from 'services/proposal/repositories/implementations/ApiProposalRepository';
import { EmptyTrashModal } from './emptyTrashModal';
import { deleteAllDocuments } from 'services/proposal/repositories/implementations/ApiProposalRepository';
import { Pagination } from '../../pagination';
import EmptyState from 'components/emptyState';
import NoTrash from '../../../../../images/empty-state/No_Trash.svg';
import { PrimaryActionButton } from 'components/emptyState/components/emptyStateActionButtons';

type Props = {
  setCurrentTab: (key: string) => void;
};

export function ListDocumentsTrash({ setCurrentTab }: Props) {
  const { t } = useTranslation();

  const { documentList, isLoadingDocumentList, setDocumentList } = useDocumentListQuery({ filterTrash: true });
  const [modalEmptyTrashVisible, setModalEmptyTrashVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const documentsPerPage = 50;
  const indexOfLastDoc = currentPage * documentsPerPage;
  const indexOfFirstDoc = indexOfLastDoc - documentsPerPage;
  const paginatedDocs = documentList.slice(indexOfFirstDoc, indexOfLastDoc);

  const handleEmptyTrashAction = async () => {
    try {
      await deleteAllDocuments();
      setDocumentList([]);
    } catch (error) {
      openNotification({
        type: 'error',
        title: t('pipeline.error.delete_error_msg'),
        description: t('pipeline.error.delete_error_description'),
        placement: 'top',
      });
    }
  };

  const handleRestoreAction = async (documentId: string) => {
    await restoreDocumentFromTrash(documentId);
  };

  const handleRestoreUndo = async (documentId: string) => {
    await moveDocumentToTrash(documentId);
  };

  const snackBarActionProp = {
    text: t('pipeline.tabs.trash_document_list.empty_action'),
    onClick: () => setModalEmptyTrashVisible(true),
  };

  const handleChangePage = (page: number) => {
    setCurrentPage(page);
  };

  const sendUserBackToDocumentsTab = () => {
    setCurrentTab('documents');
  };

  return (
    <div className="documents-list-container">
      <header className="pipeline__list-header">
        <Heading level={1}>{t('pipeline.tabs.trash_document_list.title')}</Heading>
      </header>
      {documentList.length > 0 && (
        <Pagination totalDocuments={documentList.length} onPageChange={handleChangePage} currentPage={currentPage} />
      )}
      <Snackbar
        message={t('pipeline.tabs.trash_document_list.auto_empty_message')}
        action={documentList.length ? snackBarActionProp : undefined}
      />
      <PipelineList
        type="Trash"
        documentList={paginatedDocs}
        setDocumentList={setDocumentList}
        isLoading={isLoadingDocumentList}
        listEmpty={
          <EmptyState
            headerText={t('pipeline.empty_state.trash.header')}
            supportingText={t('pipeline.empty_state.trash.supporting_text')}
            primaryGraphic={NoTrash}
            primaryActionButton={
              <PrimaryActionButton buttonText={t('pipeline.empty_state.trash.button_text')} onClick={sendUserBackToDocumentsTab} />
            }
          />
        }
        currentPage={currentPage}
        handleChangePage={handleChangePage}
        action={{
          message: t('pipeline.tabs.trash_document_list.action_message'),
          tooltipText: t('pipeline.tabs.trash_document_list.action_tooltip'),
          onAction: handleRestoreAction,
          onUndo: handleRestoreUndo,
          icon: <Icon name="IcoRestoreDocument" />,
        }}
      />
      <EmptyTrashModal onConfirm={handleEmptyTrashAction} visible={modalEmptyTrashVisible} setVisible={setModalEmptyTrashVisible} />
    </div>
  );
}
