import { ApiAccountRepository } from 'services/repositories/implementations/ApiAccountRepository';
import { useQuery } from 'react-query';
import { QueryKeys } from 'utils/queryKeys';

export function useIsPaused() {
  const apiAccountRepository = new ApiAccountRepository();

  const { data: paused, isError } = useQuery(QueryKeys.currentAccount, () => apiAccountRepository.getAccountPaused(), {
    select: (data) => data.paused,
  });

  if (isError || typeof paused === 'undefined') {
    return false;
  }

  return paused;
}
