import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';

import Button from 'components/button';
import Icon from 'components/icon';
import Input from 'components/input';
import { CreatableEmailPicker, loadOptions } from './creatable-email-picker';

import { BodyField } from './body-field';
import { DocumentShareForm } from './form';
import { To } from './to';
import { SubmitHandler, useShareForm } from './useShareForm';
import { useCurrentUser } from 'providers/UserProvider';

import './styles.less';
import { useGetDocumentByIdQuery } from 'hooks/useGetDocumentByIdQuery';

interface ShareFormProps {
  onSubmit: SubmitHandler;
  companyId?: number | null;
  accountId: number;
  documentId: string;
}

export function ShareForm({ onSubmit, companyId, accountId, documentId }: ShareFormProps) {
  const { t } = useTranslation();
  const [viewCc, setViewCc] = useState(false);
  const [viewBcc, setViewBcc] = useState(false);
  const { data } = useCurrentUser();
  const { control, handleSubmit, formState } = useShareForm();
  const { data: documentData } = useGetDocumentByIdQuery(documentId);
  const prospect = documentData?.prospect;

  return (
    <DocumentShareForm onSubmit={handleSubmit(onSubmit)}>
      <DocumentShareForm.Title>{t('document.share.form.title')}</DocumentShareForm.Title>
      <DocumentShareForm.Fields>
        <Controller
          name="recipients"
          control={control}
          rules={{ required: t('share.form.to_required') }}
          render={({ field, fieldState }) => {
            return (
              <DocumentShareForm.Field label={t('share.form.to')} invalid={fieldState.invalid}>
                <To
                  {...field}
                  loadOptions={loadOptions(accountId)}
                  viewBcc={viewBcc}
                  viewCc={viewCc}
                  onShowCc={() => setViewCc(true)}
                  onShowBcc={() => setViewBcc(true)}
                  additionalData={{ companyId: documentData?.prospect?.id }}
                  prospect={prospect}
                />
              </DocumentShareForm.Field>
            );
          }}
        />

        {viewCc && (
          <DocumentShareForm.Field label={t('share.form.cc')}>
            <Controller
              name="cc"
              control={control}
              render={({ field }) => {
                return (
                  <CreatableEmailPicker
                    {...field}
                    loadOptions={loadOptions(accountId)}
                    className="multiple-inputs"
                    placeholder={t('share.form.cc_placeholder')}
                    isOptionDisabled={() => field.value.length >= 15}
                    additionalData={{ companyId: companyId }}
                  />
                );
              }}
            />
          </DocumentShareForm.Field>
        )}
        {viewBcc && (
          <DocumentShareForm.Field label={t('share.form.bcc')}>
            <Controller
              name="bcc"
              control={control}
              render={({ field }) => {
                return (
                  <CreatableEmailPicker
                    {...field}
                    loadOptions={loadOptions(accountId)}
                    className="multiple-inputs"
                    placeholder={t('share.form.cc_placeholder')}
                    isOptionDisabled={() => field.value.length >= 15}
                    additionalData={{ companyId: companyId }}
                  />
                );
              }}
            />
          </DocumentShareForm.Field>
        )}
        <DocumentShareForm.Field label={t('share.form.from')}>
          <div className="from__container">
            <div className="from-value">{data.displayName}</div>
          </div>
        </DocumentShareForm.Field>
        <Controller
          name="subject"
          control={control}
          rules={{ required: t('share.form.subject_required') }}
          render={({ field, fieldState }) => {
            return (
              <DocumentShareForm.Field label={t('share.form.subject')} invalid={fieldState.invalid}>
                <Input
                  {...field}
                  data-testid="input-subject"
                  ariaLabel={t('share.form.subject')}
                  placeholder={t('share.form.subject_placeholder')}
                  maxLength={255}
                />
              </DocumentShareForm.Field>
            );
          }}
        />

        <Controller
          name="body"
          control={control}
          rules={{ min: { message: t('share.form.message'), value: 1 } }}
          render={({ field, fieldState }) => {
            return (
              <DocumentShareForm.Field invalid={fieldState.invalid}>
                <BodyField {...field} placeholder={t('share.form.message')} />
                <textarea {...field} style={{ visibility: 'hidden' }} aria-hidden></textarea>
              </DocumentShareForm.Field>
            );
          }}
        />
      </DocumentShareForm.Fields>
      {Object.keys(formState.errors).length > 0 && (
        <ul className="error-list">
          {Object.values(formState.errors).map((error) => {
            return (
              <li className="error-message" key={error.message}>
                {error.message}
              </li>
            );
          })}
        </ul>
      )}
      <DocumentShareForm.Footer>
        <Button
          disabled={formState.isSubmitting}
          type="primary"
          variant="positive"
          className="send-email"
          icon={<Icon name="IcoSend" title={t('share.form.submit')} />}
          htmlType={'submit'}
        >
          {t(formState.isSubmitting ? 'share.form.sending' : 'share.form.submit')}
        </Button>
      </DocumentShareForm.Footer>
    </DocumentShareForm>
  );
}
