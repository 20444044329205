import { Grid } from '@mui/material';
import { SecondaryMenuItemTitle } from './SecondaryMenuTitles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import React, { useEffect, useState } from 'react';

type NumInputBoxTwoColProps = {
  title?: string;
  ariaLabel?: string;
  onChange?: (value: number) => void;
  inputValue: number;
  testId?: string;
  limitedNum?: number | null;
  disableNegativeNum?: boolean;
};

export const NumInputBoxTwoCol = ({
  title = 'title',
  inputValue = 0,
  onChange,
  testId,
  ariaLabel = 'px',
  limitedNum = null,
  disableNegativeNum = false,
}: NumInputBoxTwoColProps) => {
  const [value, setValue] = useState<number>(0);

  useEffect(() => {
    if (!isNaN(inputValue)) setValue(inputValue);
  });

  const inputBoxSX = {
    width: '84px',
    height: '40px',
  };

  const containerSX = {
    width: '230px',
    height: '40px',
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;

    let parsedValue = parseInt(newValue, 10);

    if (isNaN(parsedValue)) {
      parsedValue = 0;
      setValue(0);
      onChange?.(0);
    } else {
      if (disableNegativeNum && parsedValue < 0) {
        parsedValue = 0;
      }

      if (limitedNum !== null && parsedValue > limitedNum) {
        parsedValue = limitedNum;
      }
    }

    setValue(parsedValue);
    onChange?.(parsedValue);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value);
    if (isNaN(value)) {
      setValue(0);
      if (onChange) {
        onChange(0);
      }
    }
  };

  return (
    <Grid data-testid={testId} container alignItems="center" justifyContent="space-between" sx={containerSX}>
      <SecondaryMenuItemTitle>{title}</SecondaryMenuItemTitle>
      <Grid item sx={inputBoxSX}>
        <OutlinedInput
          size="small"
          endAdornment={<InputAdornment position="end">{ariaLabel}</InputAdornment>}
          inputProps={{
            'aria-label': ariaLabel,
            type: 'text',
            step: 'any',
            min: 0,
            max: limitedNum,
            onChange: handleChange,
            onBlur: handleBlur,
            value,
            onClick: (e) => e.currentTarget.select(),
          }}
          sx={{
            '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button': {
              WebkitAppearance: 'none', // Hide the up and down arrows
              margin: 0,
            },
          }}
        />
      </Grid>
    </Grid>
  );
};
