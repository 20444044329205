import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import './style.less';
import TextTableButton from '../toolbar/TextTableButton';

export default function TableLibrary() {
  const { t } = useTranslation();

  return (
    <div className="table__library__container">
      <div className="table__container_title">{t('document.table.title_tables')}</div>
      <div className="table__content__section">
        <Row className="blocks-row" gutter={8}>
          <Col data-testid="table-block-button" span={12}>
            <TextTableButton title={t('document.table.text_table')} />
          </Col>
        </Row>
      </div>
    </div>
  );
}

TableLibrary.displayName = 'TableLibrary';
