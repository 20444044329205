import { ApiPlanRepository } from '../services/repositories/implementations/ApiPlanRepository';
import { usePlanApiData } from './usePlanApiData';

type useIsTrialExpiredParams = {
  accountId: number;
  enabled: boolean;
};

export function useIsTrialExpired({ accountId, enabled }: useIsTrialExpiredParams) {
  const apiPlanRepository = new ApiPlanRepository();
  const { planData, isError } = usePlanApiData({
    planRepository: apiPlanRepository,
    accountId,
    enabled: !!enabled,
  });

  if (isError || typeof planData === 'undefined') return false;
  const { isTrialExpired } = planData;

  return isTrialExpired;
}
