import { Button, Tooltip, openNotification } from 'components';
import { useTranslation } from 'react-i18next';
import { Document } from 'services/documents/entities/Document';
import { ReactNode } from 'react';
import { openToast } from 'components/toast';
import { listUndoObserver } from '../../listUndoObserver';

export type Props = {
  listType: 'Documents' | 'Trash';
  documentList: Document[];
  documentToTrash: Document;
  setDocumentList: (documents: Document[] | ((prev: Document[]) => Document[])) => Promise<void>;
  isLoading: boolean;
  action: {
    icon: ReactNode;
    message: string;
    tooltipText: string;
    onAction: (documentId: string) => Promise<void>;
    onUndo: (documentId: string) => Promise<void>;
  };
};

export function TrashDocumentAction({ listType, documentList, documentToTrash, setDocumentList, action }: Props) {
  const { t } = useTranslation();

  const handleActionClick = async (documentToTrash: Document, event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const originalList = [...documentList];
    removeDocumentFromList(documentToTrash, originalList); //before the API call to avoid delay

    try {
      await action.onAction(documentToTrash.id);
      handleTrashActionSuccess(documentToTrash, originalList);
    } catch (error) {
      restoreDocumentToList(originalList);
      handleTrashActionFailure();
    }
  };

  const removeDocumentFromList = (documentToTrash: Document, list: Document[]) => {
    setDocumentList(list.filter((document) => document.id !== documentToTrash.id));
  };

  const restoreDocumentToList = (list: Document[]) => {
    setDocumentList(list);
  };

  const handleTrashActionFailure = () => {
    openNotification({
      type: 'error',
      title: t('pipeline.error.delete_error_msg'),
      description: t('pipeline.error.error_description'),
      placement: 'top',
    });
  };

  const handleTrashUndo = async (documentToTrash: Document, list: Document[]) => {
    restoreDocumentToList(list); //before the API call to avoid delay

    try {
      await action.onUndo(documentToTrash.id);
      listUndoObserver.notify({ listType, documentId: documentToTrash.id });
    } catch (error) {
      removeDocumentFromList(documentToTrash, list);
      handleTrashActionFailure();
    }
  };

  const handleTrashActionSuccess = async (documentToTrash: Document, list: Document[]) => {
    openToast({
      message: action.message,
      onUndo: async () => {
        await handleTrashUndo(documentToTrash, list);
      },
    });
  };

  return (
    <Tooltip placement={'bottom'} title={action.tooltipText} className="pipeline-documents-tooltip">
      <Button
        icon={action.icon}
        variant="neutral"
        type="default"
        className="trash-button"
        onClick={(event) => handleActionClick(documentToTrash, event)}
        data-testid={`trash-area-${documentToTrash.title}`}
      ></Button>
    </Tooltip>
  );
}
