import './styles.less';
import { Box, Button, Snackbar, Alert } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridColTypeDef,
  GridRenderEditCellParams,
  useGridApiContext,
  GridCellEditStopReasons,
  GridPreProcessEditCellProps,
} from '@mui/x-data-grid';
import InputBase, { InputBaseProps } from '@mui/material/InputBase';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import { apiClient as apiWrapper } from 'services/api';
import { Endpoints } from 'utils/endpoints';
import { useState, useLayoutEffect, useCallback, useRef } from 'react';
import { RotateConfirmationDialog, PostRotateDialog } from './rotate-secret-dialogs';

type ApiClient = {
  identifier: string;
  name: string;
  redirectUri: string[] | string;
};

type ApiClientWithSecret = {
  identifier: string;
  name: string;
  redirectUri: string[] | string;
  secret: string;
};

interface ApiClientsDataGridProps {
  data: ApiClient[];
}

const isKeyboardEvent = (event: any): event is React.KeyboardEvent => {
  return !!event.key;
};

function EditTextarea(props: GridRenderEditCellParams<any, string>) {
  const { id, field, value, colDef, hasFocus } = props;
  const initialValue = Array.isArray(value) ? value.join('\n') : '';

  const [valueState, setValueState] = useState(initialValue);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const apiRef = useGridApiContext();

  useLayoutEffect(() => {
    if (hasFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [hasFocus]);

  const handleRef = useCallback((el: HTMLElement | null) => {
    setAnchorEl(el);
  }, []);

  const handleChange = useCallback<NonNullable<InputBaseProps['onChange']>>(
    (event) => {
      const newValue = event.target.value;
      setValueState(newValue);
      apiRef.current.setEditCellValue({ id, field, value: newValue, debounceMs: 200 }, event);
    },
    [apiRef, field, id]
  );

  return (
    <div style={{ position: 'relative', alignSelf: 'flex-start' }}>
      <div
        ref={handleRef}
        style={{
          height: 1,
          width: colDef.computedWidth,
          display: 'block',
          position: 'absolute',
          top: 0,
        }}
      />
      {anchorEl && (
        <Popper open anchorEl={anchorEl} placement="bottom-start">
          <Paper elevation={1} sx={{ p: 1, minWidth: colDef.computedWidth }}>
            <InputBase
              multiline
              rows={valueState.split('\n').length}
              value={valueState}
              sx={{ textarea: { resize: 'none' }, width: '100%' }}
              onChange={handleChange}
              inputRef={inputRef}
              onResize={() => null}
            />
          </Paper>
        </Popper>
      )}
    </div>
  );
}

export const ApiClientsDataGrid = ({ data }: ApiClientsDataGridProps) => {
  const [error, setError] = useState<string | null>(null);
  const [rotateDialogOpen, setRotateDialogOpen] = useState(false);
  const [postRotateDialogOpen, setPostRotateDialogOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [rotatedValue, setRotatedValue] = useState<ApiClientWithSecret | null>(null);

  const handlePostRotateDialogClose = () => {
    setPostRotateDialogOpen(false);
  };

  const handleRotateDialogOpen = (identifier) => {
    setSelectedId(identifier);
    setRotateDialogOpen(true);
  };

  const handleRotateDialogClose = () => {
    setRotateDialogOpen(false);
  };

  const handleRotateDialogAgree = async (identifier) => {
    const response = await apiWrapper.post(Endpoints.rotateAPIClientSecret(identifier), []);
    setRotatedValue(response as ApiClientWithSecret);
    setRotateDialogOpen(false);
    setPostRotateDialogOpen(true);
  };

  const handleProcessRowUpdateError = () => {
    setError('An error occurred while updating the row.');
  };

  const updateApiClientOnServer = (updatedRow: ApiClient, oldRow: ApiClient) => {
    if (updatedRow.redirectUri.length === 0) {
      setError('Redirect URI cannot be empty.');
      return oldRow;
    }

    if (!Array.isArray(updatedRow.redirectUri)) {
      updatedRow.redirectUri = updatedRow.redirectUri.split('\n').map((item: string) => item.trim());
    }

    apiWrapper.put(Endpoints.updateAPIClient(updatedRow.identifier), {
      name: updatedRow.name,
      redirectUri: updatedRow.redirectUri,
    });

    return updatedRow;
  };

  const multilineColumn: GridColTypeDef = {
    type: 'string',
    renderEditCell: (params) => <EditTextarea {...params} />,
  };

  const columns: GridColDef<(typeof data)[number]>[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      editable: true,
      cellClassName: 'api-client-cell',
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const hasError = params.props.value.length < 1;
        return { ...params.props, error: hasError };
      },
      renderCell: (params) => <strong>{params.value}</strong>,
    },
    {
      field: 'redirectUri',
      cellClassName: 'api-client-cell',
      headerName: 'Redirect Uri(s)',
      flex: 2,
      renderCell: (params) => (
        <Box
          sx={{
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word',
            lineHeight: 1.5,
          }}
        >
          {params.value.join('\n')}
        </Box>
      ),
      editable: true,
      ...multilineColumn,
    },
    { field: 'identifier', headerName: 'ID', flex: 3, cellClassName: 'api-client-cell' },
    {
      field: 'actions',
      cellClassName: 'api-client-cell',
      headerName: 'Manage',
      flex: 2,
      renderCell: (params) => (
        <>
          <Button
            variant="outlined"
            onClick={() => handleRotateDialogOpen(params.row.identifier)}
            sx={{
              borderRadius: 100,
              fontFamily: 'inherit',
              borderColor: '#00283A',
              color: 'rgba(0, 40, 58, 1)',
              padding: '6px 16px',
              fontSize: 14,
              lineHeight: 1.5,
              '&:hover': {
                background: 'rgba(0, 101, 140, 0.08)',
                borderColor: '#00283A',
              },
            }}
          >
            Rotate Secret
          </Button>
        </>
      ),
    },
  ];

  const getRowHeight = (params) => {
    const baseHeight = 30;
    const itemHeight = 20;
    const padding = 20;
    const itemsLength = params.model.redirectUri.length;
    return baseHeight + itemsLength * itemHeight + padding;
  };

  return (
    <Box
      sx={{
        width: '100%',
        margin: 'auto',
        '& .Mui-error': {
          bgcolor: (theme) => `rgb(126,10,15, ${theme.palette.mode === 'dark' ? 0 : 0.1})`,
          color: (theme) => (theme.palette.mode === 'dark' ? '#ff4343' : '#750f0f'),
        },
      }}
    >
      <DataGrid
        rows={data}
        columns={columns}
        onCellEditStop={(params, event) => {
          if (params.reason !== GridCellEditStopReasons.enterKeyDown) {
            return;
          }
          if (isKeyboardEvent(event) && !event.ctrlKey && !event.metaKey) {
            event.defaultMuiPrevented = true;
          }
        }}
        getRowId={(row) => row.identifier}
        getRowHeight={getRowHeight}
        processRowUpdate={(updatedRow, oldRow) => updateApiClientOnServer(updatedRow, oldRow)}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        hideFooter={true}
        initialState={{
          density: 'comfortable',
        }}
        sx={{
          border: 'none',
          '& .api-client-cell': {
            padding: '13px',
            lineHeight: 1.5,
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            textTransform: 'uppercase',
          },
          fontFamily: 'inherit',
        }}
      />
      <RotateConfirmationDialog
        open={rotateDialogOpen}
        onClose={handleRotateDialogClose}
        onAgree={() => handleRotateDialogAgree(selectedId)}
      />
      <PostRotateDialog open={postRotateDialogOpen} onClose={handlePostRotateDialogClose} rotatedValue={rotatedValue} />
      {error && (
        <Snackbar open autoHideDuration={6000} onClose={() => setError(null)}>
          <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }}>
            {error}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};
