// import { DataGridCustomAutoGrowingInput } from '../../../../../muiTheme/DataGridCustomAutoGrowingInput/DataGridCustomAutoGrowingInput';
import { TableRowTypes } from '../../../../../muiTheme/MuiDataGrid';
import { TableColumnType } from '../../../grid/reduxStore/saveHandlers';

export function useApplyRulesToColumn() {
  const applyCustomRulesToColumns = (columns: TableColumnType[]): TableColumnType[] => {
    let updatedColumns: TableColumnType[] = [...columns];
    updatedColumns = setCustomInputToDefaultColumn(updatedColumns);
    updatedColumns = setColumnsColSpanRuleForFooter(updatedColumns);

    return updatedColumns;
  };

  const setCustomInputToDefaultColumn = (columns: TableColumnType[]): TableColumnType[] => {
    for (let i = 0; i < columns.length; i += 1) {
      if (!columns[i].type || columns[i].type === 'string') {
        columns[i] = {
          ...columns[i],
          // temporary disabled regarding the product's decision
          /*renderEditCell: (params) => {
            return <DataGridCustomAutoGrowingInput {...params} />;
          },*/
        };
      }
    }
    return columns;
  };

  const setColumnsColSpanRuleForFooter = (columns: TableColumnType[]): TableColumnType[] => {
    const firstColumnAfterId = 1;

    columns[firstColumnAfterId] = {
      ...columns[firstColumnAfterId],
      colSpan: (_value, row): number => {
        let colSpan = 1;
        if (row.rowType === TableRowTypes.FOOTER) {
          // -1 because the first row is the id, which is not visible
          colSpan = columns.length - 1;
        }
        return colSpan;
      },
    };

    return columns;
  };

  return { applyCustomRulesToColumns };
}
