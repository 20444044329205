import { v4 as uuidv4 } from 'uuid';
import { TableRowTypes } from '../../../../../muiTheme/MuiDataGrid';
import { defaultRowMeta, defaultColumnMeta, initialColumnWidth } from './variables';
import { TableColumnType } from '../../../grid/reduxStore/saveHandlers';

export const columns: TableColumnType[] = [
  { field: 'id', headerName: 'ID', width: 90, editable: false },
  {
    ...defaultColumnMeta,
    field: 'column1',
    width: initialColumnWidth,
  },
  {
    ...defaultColumnMeta,
    field: 'column2',
    width: initialColumnWidth,
  },
];

export const rows = [
  { id: uuidv4(), column1: 'Text', column2: 'Text', rowType: TableRowTypes.HEADER, ...defaultRowMeta },
  { id: uuidv4(), column1: 'Enter text here...', column2: 'Or add a new row...', rowType: TableRowTypes.BODY, ...defaultRowMeta },
  {
    id: uuidv4(),
    column1: 'Maybe change the row type!',
    column2: 'Or add another column!',
    rowType: TableRowTypes.BODY,
    ...defaultRowMeta,
  },
];
