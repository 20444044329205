import EmptyState from 'components/emptyState';
import NoDocuments from '../../../../images/empty-state/No_Documents.svg';
import { PrimaryActionButton } from 'components/emptyState/components/emptyStateActionButtons';
import { useTranslation } from 'react-i18next';
import { CreateDocumentButton } from '../create-document-button';

type ShowEmptyStateProps = {
  selectedItemStatus: string[];
  clearStatusFilters: () => void;
};

export const DocumentsListEmptyState = ({ selectedItemStatus, clearStatusFilters }: ShowEmptyStateProps) => {
  const { t } = useTranslation();

  if (selectedItemStatus.length > 1) {
    return (
      <EmptyState
        primaryGraphic={NoDocuments}
        headerText={t('pipeline.empty_state.multiple_statuses_selected.header')}
        supportingText={t('pipeline.empty_state.multiple_statuses_selected.supporting_text')}
        primaryActionButton={
          <PrimaryActionButton buttonText={t('pipeline.empty_state.multiple_statuses_selected.button_text')} onClick={clearStatusFilters} />
        }
      />
    );
  }

  if (selectedItemStatus.includes('All')) {
    return (
      <EmptyState
        primaryGraphic={NoDocuments}
        headerText={t('pipeline.empty_state.header')}
        supportingText={t('pipeline.empty_state.supporting_text')}
        primaryActionButton={<CreateDocumentButton />}
      />
    );
  }

  if (selectedItemStatus.includes('Draft')) {
    return (
      <EmptyState
        primaryGraphic={NoDocuments}
        headerText={t('pipeline.empty_state.draft.header')}
        supportingText={t('pipeline.empty_state.draft.supporting_text')}
        primaryActionButton={<PrimaryActionButton buttonText={t('pipeline.empty_state.draft.button_text')} onClick={clearStatusFilters} />}
      />
    );
  }

  if (selectedItemStatus.includes('Approval')) {
    return (
      <EmptyState
        primaryGraphic={NoDocuments}
        headerText={t('pipeline.empty_state.approval.header')}
        supportingText={t('pipeline.empty_state.approval.supporting_text')}
        primaryActionButton={
          <PrimaryActionButton buttonText={t('pipeline.empty_state.approval.button_text')} onClick={clearStatusFilters} />
        }
      />
    );
  }

  if (selectedItemStatus.includes('Approved')) {
    return (
      <EmptyState
        primaryGraphic={NoDocuments}
        headerText={t('pipeline.empty_state.approved.header')}
        supportingText={t('pipeline.empty_state.approved.supporting_text')}
        primaryActionButton={
          <PrimaryActionButton buttonText={t('pipeline.empty_state.approved.button_text')} onClick={clearStatusFilters} />
        }
      />
    );
  }

  if (selectedItemStatus.includes('Sent')) {
    return (
      <EmptyState
        primaryGraphic={NoDocuments}
        headerText={t('pipeline.empty_state.sent.header')}
        supportingText={t('pipeline.empty_state.sent.supporting_text')}
        primaryActionButton={<PrimaryActionButton buttonText={t('pipeline.empty_state.sent.button_text')} onClick={clearStatusFilters} />}
      />
    );
  }

  if (selectedItemStatus.includes('Viewed')) {
    return (
      <EmptyState
        primaryGraphic={NoDocuments}
        headerText={t('pipeline.empty_state.viewed.header')}
        supportingText={t('pipeline.empty_state.viewed.supporting_text')}
        primaryActionButton={<PrimaryActionButton buttonText={t('pipeline.empty_state.viewed.button_text')} onClick={clearStatusFilters} />}
      />
    );
  }

  if (selectedItemStatus.includes('Unsigned')) {
    return (
      <EmptyState
        primaryGraphic={NoDocuments}
        headerText={t('pipeline.empty_state.awaiting_signature.header')}
        supportingText={t('pipeline.empty_state.awaiting_signature.supporting_text')}
        primaryActionButton={
          <PrimaryActionButton buttonText={t('pipeline.empty_state.awaiting_signature.button_text')} onClick={clearStatusFilters} />
        }
      />
    );
  }

  if (selectedItemStatus.includes('Lost')) {
    return (
      <EmptyState
        primaryGraphic={NoDocuments}
        headerText={t('pipeline.empty_state.lost.header')}
        supportingText={t('pipeline.empty_state.lost.supporting_text')}
        primaryActionButton={<PrimaryActionButton buttonText={t('pipeline.empty_state.lost.button_text')} onClick={clearStatusFilters} />}
      />
    );
  }

  if (selectedItemStatus.includes('Won')) {
    return (
      <EmptyState
        primaryGraphic={NoDocuments}
        headerText={t('pipeline.empty_state.won.header')}
        supportingText={t('pipeline.empty_state.won.supporting_text')}
        primaryActionButton={<PrimaryActionButton buttonText={t('pipeline.empty_state.won.button_text')} onClick={clearStatusFilters} />}
      />
    );
  }

  return null;
};
