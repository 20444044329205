import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { IntegrationsDatas } from 'providers/interfaces';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useIntegrationsApiData } from 'hooks/useIntegrationsApiData';
import { navigationItems, getNavigationItemsWithIntegrationChildren } from 'utils/navigationItems';
import { SectionHeaderProps } from './interface';
import MobileSectionHeader from './MobileSectionHeader';
import DesktopSectionHeader from './DesktopSectionHeader';

import './styles.less';

const SectionHeader: React.FC<SectionHeaderProps> = ({ title, submenuItems, userIntegrationRepository }) => {
  const { isMobile } = useMediaQuery();
  const [headerTitle, setHeaderTitle] = useState(title);
  const [headerSubmenuItems, setHeaderSubmenuItems] = useState(submenuItems);
  const { userIntegrationData }: { userIntegrationData: IntegrationsDatas } = useIntegrationsApiData({
    apiUserIntegrationsRepository: userIntegrationRepository,
  });

  const { pathname } = useLocation();

  const setSectionHeaderData = (navigationData: typeof navigationItems) => {
    const currentPath = navigationData.filter(({ href }) => {
      return pathname.includes(href);
    });

    if (currentPath.length > 0) {
      const { title, children } = currentPath[0];
      setHeaderTitle(title);
      setHeaderSubmenuItems(children);
    }
  };

  useEffect(() => {
    if (!navigationItems) return;
    let newNavigationItems;

    if (userIntegrationData) {
      newNavigationItems = getNavigationItemsWithIntegrationChildren(userIntegrationData);
    }

    if (newNavigationItems) setSectionHeaderData(newNavigationItems);
    else setSectionHeaderData(navigationItems);
  }, [pathname]);

  useEffect(() => {
    if (!userIntegrationData) return;

    const newNavigationItems = getNavigationItemsWithIntegrationChildren(userIntegrationData);

    if (newNavigationItems) setSectionHeaderData(newNavigationItems);
  }, [userIntegrationData]);

  return (
    <div className="proposify-section-header-wrapper" role="banner">
      {isMobile ? (
        <MobileSectionHeader title={headerTitle} submenuItems={headerSubmenuItems} />
      ) : (
        <DesktopSectionHeader title={headerTitle} submenuItems={headerSubmenuItems} />
      )}
    </div>
  );
};

export default SectionHeader;
