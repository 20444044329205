import { SecondaryMenu, SecondaryMenuItemTitle } from './index';
import { Grid, IconButton, Select, MenuItem, Box, FormHelperText, FormControl, SelectChangeEvent } from '@mui/material';
import { ReactNode } from 'react';

interface TableSettingsProps {
  iconButtons: {
    icon: ReactNode;
    fn?: (val?: any) => void;
    arialLabel: string;
  }[];
  handleBackButtonClick: () => void;
  handleSelectChange: (event: SelectChangeEvent) => void;
  helpText: string;
  panelTitle: string;
  selectId: string;
  selectOptions: { [type: string]: string };
  selectValue: string;
  menuTitle: string;
  testId: string;
}

const TableSettings = ({
  iconButtons,
  handleBackButtonClick,
  handleSelectChange,
  helpText,
  menuTitle,
  panelTitle,
  selectId,
  selectOptions,
  selectValue,
  testId,
}: TableSettingsProps) => {
  return (
    <SecondaryMenu testId={testId} menuTitle={menuTitle} backButtonOnClick={handleBackButtonClick}>
      <Box display="flex" justifyContent="space-between" width="100%" mx={2}>
        {iconButtons.map(({ icon, fn, arialLabel }) => (
          <IconButton key={arialLabel} aria-label={arialLabel} size="medium" color="primary" className="py-icon-button-blue" onClick={fn}>
            {icon}
          </IconButton>
        ))}
      </Box>
      <Grid container direction="column" rowGap={1}>
        <SecondaryMenuItemTitle>{panelTitle}</SecondaryMenuItemTitle>
        <FormControl size="small" fullWidth>
          <Select id={selectId} value={selectValue} onChange={handleSelectChange} size="small" MenuProps={{ disableScrollLock: true }}>
            {Object.values(selectOptions).map((option) => {
              return (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormHelperText>{helpText}</FormHelperText>
      </Grid>
    </SecondaryMenu>
  );
};

export default TableSettings;
