import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { DataGridProProps, GridRowHeightReturnValue } from '@mui/x-data-grid-pro';
import './fonts.css';

const HoverNFocus = '#49D8F1';
const white = '#ffffff';
const footerHeaderColor = '#00658C';
const defaultRowHeight = '52px';
const defaultRowFooterHeight = '40px';
const defaultFontWidth = 400;
const blueBorderStyle = `1px solid ${HoverNFocus}`;
const focusedBoxShadow = '0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)';

const handleGetRowClassName = (params) => {
  if (params.row.rowType === TableRowTypes.HEADER) return 'py-header-row';
  if (params.row.rowType === TableRowTypes.FOOTER) return 'py-footer-row';
  else return '';
};

const getRowHeight = (): GridRowHeightReturnValue => {
  return 'auto';
};

export const TableRowTypes = {
  HEADER: 'Header',
  BODY: 'Body',
  FOOTER: 'Footer',
};

export const TableRColumnTypes = {
  TEXT: 'Text',
};

export const MuiDataGrid: {
  defaultProps?: Partial<DataGridProProps<any>> | undefined;
  styleOverrides?: Partial<OverridesStyleRules> | undefined;
} = {
  defaultProps: {
    columnHeaderHeight: 33,
    showColumnVerticalBorder: true,
    hideFooter: true,
    disableColumnMenu: true,
    disableColumnSorting: true,
    getRowClassName: handleGetRowClassName,
    getRowHeight,
    autoHeight: true,
    columnVisibilityModel: {
      id: false,
    },
  },
  styleOverrides: {
    root: {
      fontSize: '1rem',
      fontFamily: 'roboto, sans-serif',
      fontWeight: defaultFontWidth,
      '.MuiDataGrid-columnSeparator--resizable.MuiDataGrid-columnSeparator--resizing': {
        color: '#13BCD5',
      },
      '&.py-selected-state ': {
        zIndex: '-1',
        '.MuiDataGrid-columnHeaders': {
          '> div': {
            display: 'none',
          },
        },
      },
      '&.py-contentful-state ': {
        zIndex: 'auto',
        '.py-selected-column-cell': {
          borderLeft: blueBorderStyle,
          borderRight: blueBorderStyle,
        },
        '.Mui-selected': {
          boxShadow: focusedBoxShadow,
        },
      },
      '.MuiDataGrid-filler': {
        display: 'none',
      },
      '.MuiDataGrid-scrollbar': {
        display: 'none',
      },
    },
    columnHeaderRow: {
      '--DataGrid-containerBackground': '#DDE3EA',
      color: '#41484D',
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: 24,
      borderBottom: '1px inner solid rgba(0, 0, 0, 0.12)',
      '.MuiDataGrid-withBorderColor': {
        borderRight: `1px solid ${white}`,
      },
    },

    columnHeader: {
      '&:hover': {
        backgroundColor: '#A3EEFF',
      },
      '&:focus, &:focus-within': {
        backgroundColor: '#A3EEFF',
        outline: 'none',
      },
    },

    row: {
      minHeight: `${defaultRowHeight} !important`,

      '&:hover': {
        backgroundColor: 'transparent',
        '--rowBorderColor': HoverNFocus,
        '.MuiDataGrid-cell': {
          borderBottom: '1px solid var(--rowBorderColor)',
        },
      },

      '&.py-header-row': {
        backgroundColor: footerHeaderColor,
        fontWeight: 600,
        color: white,
        lineHeight: 'inherit',
      },
      '&.py-footer-row': {
        backgroundColor: footerHeaderColor,
        color: white,
        '.MuiDataGrid-cell': {
          minHeight: `${defaultRowFooterHeight}`,
        },
      },
      '&.Mui-selected': {
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: 'transparent',
        },
        '&.py-header-row, &.py-footer-row': {
          backgroundColor: footerHeaderColor,
          '&:hover': {
            backgroundColor: footerHeaderColor,
          },
        },
      },
    },

    cell: {
      borderBottom: '1px solid transparent',
      overflowWrap: 'break-word',
      display: 'flex',
      alignItems: 'center',
      wordBreak: 'break-word',
      '&.MuiDataGrid-rowReorderCellContainer': {
        alignItems: 'unset',
        wordBreak: 'unset',
      },
      '&:focus': {
        outline: blueBorderStyle,
      },
      '&.MuiDataGrid-cell--editing:focus-within': {
        outline: blueBorderStyle,
      },
    },

    rowReorderCell: {
      color: 'rgba(0, 0, 0, 0.38)',
    },

    rowReorderCellPlaceholder: {
      fontWeight: defaultFontWidth,
    },
  },
};
