import { Endpoints } from '../../../utils/endpoints';
import { AccountRepository } from '../interfaces/AccountRepository';
import { PausedAccountEntity } from '../../../services/entities/PausedAccountEntity';
import { AccountApiResponse } from '../../../services/objects/http/AccountApiResponse';
import { apiClient } from 'services/api';

export class ApiAccountRepository implements AccountRepository {
  public async getAccountPaused(): Promise<PausedAccountEntity> {
    const { paused } = await apiClient.get<AccountApiResponse>(Endpoints.getCurrentAccount);

    return {
      paused: paused === 1,
    };
  }
}
