import { FunctionComponent, useCallback } from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { Typography, styled, List, ListItem, ListItemText, ListItemIcon, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { useGetDowngradeFeaturesList } from 'hooks/useGetDowngradeFeaturesList';
import { MissingFeatureEntity } from 'services/entities/MissingFeatureEntity';
import { ApiPlanFeatureRepository } from 'services/repositories/implementations/ApiPlanFeatureRepository';
import goToCinder from 'utils/goToCinder';

interface DowngradeModalProps {
  isOpen: boolean;
  setModalOpen: (state: boolean) => void;
  currentPlanId: number;
  planFeatureRepository: ApiPlanFeatureRepository;
  planType: string;
}

const DowngradeModal: FunctionComponent<DowngradeModalProps> = ({
  planFeatureRepository,
  isOpen,
  setModalOpen,
  currentPlanId,
  planType,
}) => {
  const { t } = useTranslation();

  const newPlanId =
    planType === 'basic'
      ? Number(process.env.REACT_APP_MONTHLY_BASIC_PLAN_ID || 1536)
      : Number(process.env.REACT_APP_QUARTERLY_TEAM_PLAN_ID);
  const currentPlanFeatures = useGetDowngradeFeaturesList(planFeatureRepository, currentPlanId, isOpen);
  const newPlanFeatures = useGetDowngradeFeaturesList(planFeatureRepository, newPlanId, isOpen);
  const title = t('settings.plan.downgrade_confirmation_title');

  const getMissingFeatures = useCallback((): MissingFeatureEntity[] => {
    const activeCurrentPlanFeatures = currentPlanFeatures.filter((feature) => feature.active);
    const inactiveNewPlanFeatures = newPlanFeatures.filter((feature) => !feature.active);

    return activeCurrentPlanFeatures.filter((activeCurrentPlanFeature) =>
      inactiveNewPlanFeatures.some((inactiveNewPlanFeature) => activeCurrentPlanFeature.name === inactiveNewPlanFeature.name)
    );
  }, [currentPlanFeatures, newPlanFeatures]);

  const missingFeatures = getMissingFeatures();

  const handleDowngradeRedirect = () => {
    switch (planType) {
      case 'basic':
        goToCinder('settings/plan/Basic');
        break;
      case 'team':
        goToCinder('settings/plan/Team');
        break;
    }
  };

  const StyledButton = styled(Button)(() => ({
    borderRadius: '100px',
    boxShadow: 'none',
    fontWeight: 600,
    textTransform: 'initial',
  }));

  return (
    <Dialog
      open={isOpen}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '24px',
        },
      }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '12px 16px 12px 24px',
        }}
      >
        {title}
        <IconButton sx={{ ml: 'auto' }} onClick={() => setModalOpen(false)}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ pb: 3 }}>
        <Typography variant="body1">{t('settings.plan.downgrade_confirmation_description')}</Typography>
        {missingFeatures?.length > 0 ? (
          <List>
            {missingFeatures.map((missingFeature, key) => (
              <ListItem key={`missing-feature-${key}`} sx={{ px: 0, py: '4px' }}>
                <ListItemIcon>
                  <CloseIcon color={'error'} />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant="body2" color="primary">
                      {missingFeature.name}
                    </Typography>
                  }
                  secondary={
                    <Typography variant="body2" color="primary.light">
                      {missingFeature.onDowngradeDescription}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        ) : null}
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <StyledButton size="medium" variant="text" color="primary" onClick={handleDowngradeRedirect}>
          {t('settings.plan.button_confirm_downgrade_plan_label')}
        </StyledButton>
        <StyledButton autoFocus onClick={() => setModalOpen(false)} size="medium" variant="contained" color="primary">
          {t('settings.plan.button_cancel_downgrade_plan_label')}
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

export default DowngradeModal;
