import { PlanTier, PlanTierButtonConfigProps } from './interface';

export const availableTiers: PlanTier[] = ['basic', 'team', 'business'];
export const allTiers: PlanTier[] = ['', 'free', 'basic', 'team', 'business', 'employee'];

export const getPlanTier = (planData: any): PlanTier => {
  if (!planData || !planData.name) {
    return planData?.custom ? <PlanTier>'business' : <PlanTier>'';
  }
  const foundTier = planData.type !== '' ? getPlanTierByString(planData.type) : getPlanTierByString(planData.name.toLowerCase());
  return foundTier === '' && planData.custom ? <PlanTier>'business' : foundTier;
};

export const getPlanTierByString = (planName: string): PlanTier => {
  const foundTier = allTiers.indexOf(<PlanTier>planName.toLowerCase());
  if (allTiers[foundTier] === 'employee') {
    return <PlanTier>'business';
  }
  return allTiers[foundTier] || <PlanTier>'';
};

export const isPlanUpgrade = (planName: PlanTier, curTier: PlanTier): boolean => {
  const curPlanPosition = allTiers.indexOf(curTier);
  const newPlanPosition = allTiers.indexOf(planName);

  return newPlanPosition > curPlanPosition;
};

export const canChangeToPlan = (planName: PlanTier, curTier: PlanTier, canDowngrade = true): boolean => {
  if (curTier === planName) {
    return false;
  }
  return !isPlanUpgrade(planName, curTier) && !canDowngrade ? false : true;
};

export const getPlanButtonLabel = (planName: PlanTier, curTier: PlanTier, options?: PlanTierButtonConfigProps): string => {
  const curPlanPosition = allTiers.indexOf(curTier);
  const buttonPlanPosition = allTiers.indexOf(planName);

  if (curPlanPosition === buttonPlanPosition) {
    return options?.current || 'settings.plan.button_current_plan_label';
  }

  return curPlanPosition > buttonPlanPosition
    ? options?.downgrade || 'settings.plan.button_downgrade_plan_label'
    : options?.upgrade || 'settings.plan.button_upgrade_plan_label';
};
