import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Button } from '@mui/material';
import Add from '../../../../components/icon/icons/IcoAdd';
import { CreateAssetModal } from '../create-asset-modal';

interface CreateAssetButtonProps {
  variant?: 'contained' | 'extended';
}

export function CreateAssetButton({ variant }: CreateAssetButtonProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Button onClick={handleOpen} variant={variant} data-testid="create-asset-button" disableRipple>
        <Add width="24px" height="24px" />
        {t('library.new_asset_button')}
      </Button>
      <CreateAssetModal open={open} handleClose={handleClose} />
    </>
  );
}
