import React from 'react';
import { PipelineTabs } from './components/tabs/index';
import { FenixThemeProvider } from 'providers/FenixThemeProvider';

const PipelinePage: React.FC = () => {
  return (
    <FenixThemeProvider>
      <PipelineTabs />
    </FenixThemeProvider>
  );
};

export default PipelinePage;
