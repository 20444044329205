export const MuiIconButton = {
  styleOverrides: {
    root: {
      '&.py-icon-button-blue': {
        backgroundColor: '#C5E7FF',
        '&:hover': {
          backgroundColor: ' rgba(0, 0, 0, 0.04)',
        },
      },
    },
  },
};
