import React from 'react';
import { Paragraph } from '../../../components';
import './blockSourceButton.less';
import IcoTable from '../../icon/icons/IcoTable';
import { useDrag } from 'react-dnd';

interface TextTableButtonProps {
  title: string;
}

const TextTableButton: React.FunctionComponent<TextTableButtonProps> = ({ title }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'TABLE',
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));
  return (
    <button ref={drag} draggable className="block-source" style={{ opacity: isDragging ? 0.5 : 1 }}>
      <div className="block-source-static-icon">
        <IcoTable />
      </div>
      <Paragraph size="sm">{title}</Paragraph>
    </button>
  );
};

export default TextTableButton;
TextTableButton.displayName = 'TextTableButton';
