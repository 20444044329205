import { useState, useContext, useEffect } from 'react';
import { SelectionContext } from '../SelectedBlockInfoProvider';
import { resetAllPanels } from '../../grid/reduxStore/blockStyleSettingsSlice';
import { useSectionId } from '../../Sections/SectionIdProvider';
import { useAppDispatch } from '../../grid/reduxStore/Store';

const useBlockState = (blockId: string) => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const { selectedBlockIdByWrapper, setSelectedBlockInfoByWrapper } = useContext(SelectionContext);
  const dispatch = useAppDispatch();

  const sectionId = useSectionId();
  useEffect(() => {
    if (!selectedBlockIdByWrapper) setIsEditMode(false);
  }, [selectedBlockIdByWrapper]);

  const handleOnMouseDown = (e) => {
    const isDoubleClick = e.detail === 2;

    if (selectedBlockIdByWrapper === blockId) {
      if (isDoubleClick) {
        setIsEditMode(true);
      }
    } else {
      setSelectedBlockInfoByWrapper({ blockId: blockId, sectionId: sectionId });
      dispatch(resetAllPanels());
    }
  };

  return { handleOnMouseDown, isEditMode };
};

export default useBlockState;
