import { gridPixelSize } from '../../../shared/gridConfig';
import { Box } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { columns, rows } from '../Table';
import { PreviewBlock } from './PreviewBlock';
import { BlockContent } from '../../../grid/reduxStore/editorSlice';
import { useApplyRulesToColumn } from '../Table/useApplyRulesToColumn';
import { TableColumnType } from '../../../grid/reduxStore/saveHandlers';

interface PreviewTableBlockProps {
  block: BlockContent;
}

export function PreviewTableBlock({ block }: PreviewTableBlockProps) {
  const tableColumns: TableColumnType[] = (block?.contentTable?.columns || columns) as TableColumnType[];

  const { applyCustomRulesToColumns } = useApplyRulesToColumn();

  return (
    <PreviewBlock key={block.blockConfig.id} gridBlock={block} gridSystemInPx={gridPixelSize}>
      <Box>
        <DataGridPro
          rows={block?.contentTable?.rows || rows}
          columns={applyCustomRulesToColumns(tableColumns)}
          isCellEditable={() => false}
          style={{ width: block.blockConfig.width }}
          className={'py-selected-state'}
        />
      </Box>
    </PreviewBlock>
  );
}
