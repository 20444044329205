import { useState } from 'react';
import { Drawer } from 'antd';
import HamburgerButton from '../hamburgerButton';
import Logo from '../logo';
import MenuBody from './menuBody';
import { Link } from 'react-router-dom';
import './styles.less';
import { Icons } from '../icon/icons';

const { IcoCloseX } = Icons;

const Menu = (): JSX.Element => {
  const [visible, setVisible] = useState(false);

  const showPanel = () => setVisible(true);
  const closePanel = () => setVisible(false);

  const proposifyLogo = () => {
    return (
      <Link to={'/'} key={''} onClick={closePanel}>
        <Logo title="Proposify Logo" href="/" className="dark-bg" />
      </Link>
    );
  };

  return (
    <>
      <HamburgerButton onClick={showPanel} />
      <Drawer
        title={proposifyLogo()}
        closeIcon={<IcoCloseX />}
        placement="left"
        onClose={closePanel}
        open={visible}
        className="proposify-menu"
      >
        <MenuBody onClick={closePanel} />
      </Drawer>
    </>
  );
};

export default Menu;
